import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { Link, useLocation } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';
import withRouter from '../Common/withRouter';
import { ALL_MODULES } from '../../common/constant';
import { useSelector } from "react-redux";

function SidebarContent(props) {
  let role = useSelector((state) => state?.AuthLogin?.auth?.user);
  const permissionArray = role?.role?.role_permissions;
  const uniqueModuleArray = [...new Set(permissionArray.map((item) => item.permission.module))]

  const orderModuleArray = ["Dashboard"]
  Object.values(ALL_MODULES).forEach((item) => {
    if (uniqueModuleArray.includes(item)) {
      orderModuleArray.push(item)
    } else if (item === ALL_MODULES.NON_ACTIVE_PROPERTIES && uniqueModuleArray.includes(ALL_MODULES.PROPERTIES)) {
      orderModuleArray.push(item)
    }
  })

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      // scrollElement(item);
      return false;
    }
    // scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El = parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
          ? parent.childNodes[1]
          : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  // useEffect(() => {
  //   ref.current.recalculate();
  // }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  const dynamicModule = (module, index) => {
    switch (module) {
      case 'Dashboard':
        return <li key={index}>
          <Link to="/dashboard" className="waves-effect">
            <i className="mdi mdi-view-dashboard-outline" />
            <span>{props.t('Dashboard')}</span>
          </Link>
        </li>
      case ALL_MODULES.TENANTS:
        return <li key={index}>
          <Link to="/tenants" className="waves-effect">
            <i className="mdi mdi-account-group-outline" />
            <span>{props.t('Tenants')}</span>
          </Link>
        </li>
      case ALL_MODULES.ADD_PROPERTY_REQUESTS:
        return <li key={index}>
          <Link to="/new-property-requests" className="waves-effect">
            <i className="mdi mdi-clipboard-list-outline" />
            <span>{props.t('New Property Requests')}</span>
          </Link>
        </li>
      case ALL_MODULES.OWNERS:
        return <li key={index}>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="mdi mdi-account-group-outline" />
            <span>{props.t('Owners')}</span>
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="/property-owners">
                <i className="mdi mdi-badge-account-horizontal-outline" />
                <span>{props.t('Property Owners')}</span>
              </Link>
            </li>
            <li>
              <Link to="/property-owners/prospecting">
                <i className="mdi mdi-badge-account-horizontal-outline" />
                <span>{props.t('Prospecting Owners')}</span>
              </Link>
            </li>
          </ul>
        </li>
      case ALL_MODULES.PROPERTIES:
        return <li key={index}>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="mdi mdi-domain" />
            <span>{props.t('Active Property')}</span>
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="/active-properties/active-properties-live">
                <i className="mdi mdi-check-circle-outline" />
                <span>{props.t('Live & Active')}</span>
              </Link>
            </li>
            <li>
              <Link to="/active-properties/closed-properties">
                <i className="mdi mdi-cancel" />
                <span>{props.t('Suspended & Closed')}</span>
              </Link>
            </li>
          </ul>
        </li>
      // case ALL_MODULES.NON_ACTIVE_PROPERTIES:
      //   return <li>
      //     <Link to="/non-active-properties" className="waves-effect">
      //       <i className="mdi mdi-home-city-outline" />
      //       <span>{props.t('Non Active Properties')}</span>
      //     </Link>
      //   </li>
      case ALL_MODULES.FINANCE:
        return <li key={index}>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="mdi mdi-bank" />
            <span>{props.t('Finance')}</span>
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="/rent-slips">
                <i className="mdi mdi-file-document" />
                <span>{props.t('Rent Slips')}</span>
              </Link>
            </li>
            <li>
              <Link to="/payout-slips">
                <i className="mdi mdi-file-check" />
                <span>{props.t('Payout')}</span>
              </Link>
            </li>
            <li>
              <Link to="/deposit-refund">
                <i className="mdi mdi-file-check" />
                <span>{props.t('Deposit Refund')}</span>
              </Link>
            </li>
            <li>
              <Link to="/cancelled-booking">
                <i className="mdi mdi-file-check" />
                <span>Cancelled Booking</span>
              </Link>
            </li>
          </ul>
        </li>
      case ALL_MODULES.TASKS:
        return <li key={index}>
          <Link to="/kanban-board" className="waves-effect">
            <i className="mdi mdi-ticket-confirmation-outline" />
            <span>{props.t('Tasks')}</span>
          </Link>
        </li>
      case ALL_MODULES.ROLE_PERMISSIONS:
        return <li key={index}>
          <Link to="/roles" className="waves-effect">
            <i className="mdi mdi-account-cog-outline" />
            <span>{props.t('Role Permissions')}</span>
          </Link>
        </li>
      case ALL_MODULES.COMPANY_ASSOCIATES:
        return <li key={index}>
          <Link to="/company-associates" className="waves-effect">
            <i className="mdi mdi-account-tie-outline" />
            <span>{props.t('Company Associates')}</span>
          </Link>
        </li>
      case ALL_MODULES.COMPANY_EMPLOYEES:
        return <li key={index}>
          <Link to="/company-employees" className="waves-effect">
            <i className="mdi mdi-account-tie-outline" />
            <span>{props.t('Company Employees')}</span>
          </Link>
        </li>
      case ALL_MODULES.COMPLAIN_TICKETS:
        return <li key={index}>
          <Link to="/complain-tickets" className="waves-effect">
            <i className="mdi mdi-ticket-confirmation-outline" />
            <span>{props.t('Complain Tickets')}</span>
          </Link>
        </li>
      case ALL_MODULES.PG_VACANT_REQUESTS:
        return <li key={index}>
          <Link to="/pg-vacant-request" className="waves-effect">
            <i className="mdi mdi-office-building" />
            <span>{props.t('PG Vacant Request')}</span>
          </Link>
        </li>
      case ALL_MODULES.VISIT_REQUESTS:
        return <li key={index}>
          <Link to="/visit-requests" className="waves-effect">
            <i className="mdi mdi-clipboard-list-outline" />
            <span>{props.t('Visit Requests')}</span>
          </Link>
        </li>
      case ALL_MODULES.MESSAGE:
        return <li key={index}>
          <Link to="/message" className="waves-effect">
            <i className="mdi mdi-message-processing-outline" />
            <span>{props.t('Message')}</span>
          </Link>
        </li>
      case 'Notification':
        return <li key={index}>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="mdi mdi-sync-alert" />
            <span>{props.t('Alerts')}</span>
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="/activities">
                <i className="mdi mdi-menu" />
                <span>{props.t('Activities')}</span>
              </Link>
            </li>
            <li key={index}>
              <Link to="/notifications" className="waves-effect">
                <i className="mdi mdi-bell-ring-outline" />
                <span>{props.t('Notifications')}</span>
              </Link>
            </li>
          </ul>
        </li>
      default:
        break;
    }
  }

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }
  return (
    <>

      <SimpleBar style={{ height: 'calc(100% - 110px' }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {orderModuleArray?.length > 0 && orderModuleArray.map((module, index) => (
              dynamicModule(module, index)
            ))}

            {/* commnted sidebar start */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-envelope" />
                <span>{props.t('Email')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-inbox">{props.t('Inbox')}</Link>
                </li>
                <li>
                  <Link to="/email-read">
                    {props.t('Read Email')}
                    {' '}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-invoice" />
                <span>{props.t('Invoices')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/invoices-list">{props.t('Invoice List')}</Link>
                </li>
                <li>
                  <Link to="/invoices-detail">{props.t('Invoice Detail')}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-book-alt" />
                <span>{props.t('Contacts')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/contacts-grid">{props.t('User Grid')}</Link>
                </li>
                <li>
                  <Link to="/contacts-list">{props.t('User List')}</Link>
                </li>
                <li>
                  <Link to="/contacts-profile">{props.t('Profile')}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-user-circle" />
                <span>{props.t('Authentication')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{props.t('Login')}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{props.t('Register')}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t('Recover Password')}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{props.t('Lock Screen')}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-file-alt" />
                <span>{props.t('Utility')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-starter">{props.t('Starter Page')}</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">{props.t('Maintenance')}</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">{props.t('Coming Soon')}</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">{props.t('Timeline')}</Link>
                </li>
                <li>
                  <Link to="/pages-faqs">{props.t('FAQs')}</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">{props.t('Pricing')}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{props.t('Error 404')}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{props.t('Error 500')}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li className="menu-title">{props.t('Components')}</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-flask" />
                <span>{props.t('UI Elements')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t('Alerts')}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t('Buttons')}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t('Cards')}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t('Carousel')}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t('Dropdowns')}</Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t('Grid')}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t('Images')}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t('Lightbox')}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t('Modals')}</Link>
                </li>
                <li>
                  <Link to="/ui-offcanvas">{props.t('Offcanvas')}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{props.t('Range Slider')}</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">
                    {props.t('Session Timeout')}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{props.t('Progress Bars')}</Link>
                </li>
                <li>
                  <Link to="/ui-placeholders">{props.t('Placeholders')}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">
                    {props.t('Tabs & Accordions')}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t('Typography')}</Link>
                </li>
                <li>
                  <Link to="/ui-utilities">
                    {props.t('Utilities')}
                    <span className="badge rounded-pill bg-success float-end">
                      {' '}
                      {props.t('New')}
                      {' '}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/ui-toasts">{props.t('Toasts')}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t('Video')}</Link>
                </li>
                <li>
                  <Link to="/ui-general">{props.t('General')}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">{props.t('Colors')}</Link>
                </li>
                <li>
                  <Link to="/ui-rating">{props.t('Rating')}</Link>
                </li>
                <li>
                  <Link to="/ui-notifications">{props.t('Notifications')}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="uil-shutter-alt" />
                <span className="badge rounded-pill bg-info float-end">9</span>
                <span>{props.t('Forms')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/basic-elements">{props.t('Basic Elements')}</Link>
                </li>
                <li>
                  <Link to="/form-validation">
                    {props.t('Form Validation')}
                  </Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t('Form Advanced')}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t('Form Editors')}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">
                    {props.t('Form File Upload')}
                    {' '}
                  </Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{props.t('Form Xeditable')}</Link>
                </li>
                <li>
                  <Link to="/form-repeater">{props.t('Form Repeater')}</Link>
                </li>
                <li>
                  <Link to="/form-wizard">{props.t('Form Wizard')}</Link>
                </li>
                <li>
                  <Link to="/form-mask">{props.t('Form Mask')}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-list-ul" />
                <span>{props.t('Tables')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t('Basic Table')}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t('Data Table')}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {props.t('Responsive Table')}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t('Editable Table')}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-chart" />
                <span>{props.t('Charts')}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">{props.t('Apex')}</Link>
                </li>
                <li>
                  <Link to="/chartjs-charts">{props.t('Chartjs')}</Link>
                </li>
                <li>
                  <Link to="/e-charts">{props.t('E Chart')}</Link>
                </li>
                <li>
                  <Link to="/charts-knob">{props.t('Jquery Knob')}</Link>
                </li>
                <li>
                  <Link to="/sparkline-charts">
                    {props.t('Sparkline Chart')}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-streering" />
                <span>{props.t('Icons')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-unicons">{props.t('Unicons')}</Link>
                </li>
                <li>
                  <Link to="/icons-boxicons">{props.t('Boxicons')}</Link>
                </li>
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t('Material Design')}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t('Dripicons')}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t('Font awesome')}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-location-point" />
                <span>{props.t('Maps')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t('Google Maps')}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t('Vector Maps')}</Link>
                </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-share-alt" />
                <span>{props.t('Multi Level')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t('Level 1.1')}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t('Level 1.2')}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t('Level 2.1')}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t('Level 2.2')}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}

            {/* sidebar ended */}

          </ul>

          {/* <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/global-settings" className="waves-effect">
                <i className="mdi mdi-cog"></i>
                <span>{props.t("Global Settings")}</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="waves-effect">
                <i className="mdi mdi-login-variant"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>
          </ul> */}
        </div>
      </SimpleBar>

      <SimpleBar style={{ borderTop: '1px solid #ccc' }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/global-settings" className="waves-effect">
                <i className="mdi mdi-cog" />
                <span>{props.t('Global Settings')}</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="waves-effect">
                <i className="mdi mdi-login-variant" />
                <span>{props.t('Logout')}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  );
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
