import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MiniWidget from '../Dashboard/mini-widget';
import { TIME_INTERVAL } from '../../common/constant';
import { DateRange } from "react-date-range";
import { Button } from "reactstrap";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import dayjs from 'dayjs';

function DashboardBreadcrumb(props) {
  const [label, setLabel] = useState(["This Month"]);
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: 'selection'
  }]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };
  const names = [
    'This Month',
    'Previous Month',
    'Custom Date Range'
  ];

  const handleChange = (event) => {
    let {
      target: { value },
    } = event;
    setLabel(typeof value === 'string' ? value.split(',') : value)
    if (value === TIME_INTERVAL.CUSTOM) {
      setIsShowDatePicker(true);
    } else {
      props?.emitFilterItem(value)
    }
  };

  const handleFilter = () => {
    if (selectionRange[0].startDate && selectionRange[0].endDate) {
      setIsShowDatePicker(false);
      props?.emitFilterItem([TIME_INTERVAL.CUSTOM, `${dayjs(selectionRange[0].startDate).format('YYYY-MM-DD')}/${dayjs(selectionRange[0].endDate).format('YYYY-MM-DD')}`])
    }
  }

  const borderBottomStyle = props.breadcrumbItem === 'Stats' ? '1px solid #CAD2DF' : 'none';
  return (
    <div className="page-title-box pb-2 d-flex align-items-center justify-content-between w-100" style={{ borderBottom: borderBottomStyle }}>
      <div className='d-flex align-items-center'>
        {/* <h4 className="me-2 mb-0" style={{ color: '#565656', marginRight: '2px' }}>{props.breadcrumbItem}</h4> */}
        <div className='dashboard_upper_cards upper_card_board'>
          <MiniWidget reports={props.reports} />
        </div>
      </div>
      <div className="page-title-right">
        <ol style={{ marginLeft: '2px' }}>
          {/* <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem> */}
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
            value={label}
              onChange={handleChange}
              MenuProps={MenuProps}
              style={{ width: 111, height: 31, fontSize: '12px', backgroundColor: '#fff' }}
            >
              {/* <MenuItem value="This Month">This Month</MenuItem> */}
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
          </Select>
          {isShowDatePicker &&
            (<div
              className="d-flex flex-column"
              style={{ marginLeft: '-8.7rem', zIndex: 1, position: 'absolute' }}
              onMouseLeave={() => setIsShowDatePicker(false)}
            >
              <DateRange
                editableDateInputs={true}
                onChange={item => setSelectionRange([item.selection])}
                moveRangeOnFirstSelection={true}
                ranges={selectionRange}
              />

              <Button
                className="yellow_gradient_btn"
                onClick={() => handleFilter()}>

                Filter
              </Button>
            </div>)
          }
        </ol>
      </div>
    </div>
  );
}

DashboardBreadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default DashboardBreadcrumb;
