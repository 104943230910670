import React, { useState, useEffect } from "react";
import {
    MeetingRoomOutlined,
    FmdGoodOutlined,
    MyLocationOutlined,
    PersonOutlineOutlined,
    MailOutlined,
    LocalPhoneOutlined,
    RestaurantMenu,
    CloseOutlined,
    Info
} from '@mui/icons-material';
import { Card, Button, Modal, Box, Popover, Typography, Breadcrumbs } from '@mui/material';
import PropertySlider from "./propertySlider";
import { selectAmenitiesIcon } from "../../../common/amenities_icon";
import { getS3BaseUrl, ucFirst } from "../../../helpers/string_helper";
import AddressComponent from "../../Common/Address";
import FileList from "../../Common/FileList";
import PropertyRatings from "../PropertyRatings";
import AmenitiesSlider from "./amenitiesSlider";

const amenitiesStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

function BasicDetails({ propertyDetails }) {
    const [isDocumentModal, setIsDocumentModal] = useState(false);
    const [documents, setDocuments] = useState();
    const handleClose = () => setIsDocumentModal(false);
    const handleOpen = () => setIsDocumentModal(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const s3BaseUrl = getS3BaseUrl()

    useEffect(() => {
        if (propertyDetails?.food_menu_documents?.length > 0) {
            const result = propertyDetails?.food_menu_documents?.map((item) => {
                return { ...item, location: `${s3BaseUrl}${item?.location}` }
            })
            setDocuments(result)
        }
    }, [propertyDetails])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseInfo = () => {
        setAnchorEl(null);
    };


    return (
        <div className="activeProperties activePropertyBasic">
            {propertyDetails && (
                <div className="row">
                    <div className="col-sm-5"><PropertySlider propertyDetails={propertyDetails} /></div>
                    <div className="col-sm-7">
                        <Card className="shadow-none border p-3">
                            <div className="detail_block main_details_block">
                                <div className='d-flex justify-content-between'>
                                    <h5><MeetingRoomOutlined />{ucFirst(propertyDetails?.name)}</h5>
                                    <div className='star_ratings'>
                                        <PropertyRatings property_id={propertyDetails?.id} />
                                    </div>
                                </div>
                                <div className="ico_content d-flex mb-2">
                                    <FmdGoodOutlined /> {propertyDetails.address ? (<AddressComponent address={propertyDetails.address} />) : 'N/A'}
                                </div>
                                <div className="d-flex ico_content mb-2">
                                    <MyLocationOutlined />
                                    <div className="locations d-flex">
                                        <div style={{ minWidth: '70px' }}>Latitude:</div> &nbsp;
                                        <ul className="list-inline d-flex flex-wrap mb-0">
                                            {propertyDetails?.latitude}
                                        </ul>
                                    </div>
                                </div>
                                <div className="d-flex ico_content mb-2">
                                    <MyLocationOutlined />
                                    <div className="locations d-flex">
                                        <div style={{ minWidth: '70px' }}>Longitude:</div> &nbsp;
                                        <ul className="list-inline d-flex flex-wrap mb-0">
                                            {propertyDetails?.longitude}
                                        </ul>
                                    </div>
                                </div>
                                <div className="d-flex ico_content">
                                    <MyLocationOutlined />
                                    <div className="locations d-flex">
                                        <div style={{ minWidth: '70px' }}>Near To:</div> &nbsp;
                                        <ul className="list-inline d-flex flex-wrap mb-0">
                                            {propertyDetails?.near_to ? propertyDetails?.near_to.split(',')?.map((it) => (
                                                <li key={it} className="mb-2">{it}</li>
                                            )) : 'N/A'}
                                        </ul>
                                    </div>
                                </div>
                                <div className="d-flex ico_content">
                                    <div className="locations d-flex">
                                        <RestaurantMenu style={propertyDetails?.food_menu_documents?.length > 0 ? { marginTop: '4px' } : { marginTop: '2px' }} />
                                        <div style={{ minWidth: '70px' }}>{propertyDetails?.food_menu_documents?.length > 0 ? (
                                            <span>Food Service Available
                                                <Info onClick={handleClick} className='ms-1' />
                                                <Popover
                                                    className='popup_block'
                                                    id={propertyDetails?.id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleCloseInfo}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <Typography sx={{ p: 1 }} className='popContent'>
                                                        <p>Food payment should be directly made to the canteen</p>
                                                    </Typography>
                                                </Popover>
                                                <Button onClick={handleOpen}>View Menu Card</Button>
                                            </span>
                                        ) : <span>Food Service Not Available </span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {propertyDetails?.user && (
                                <div className="detail_block main_details_block">
                                    <h6>Property Owner’s Details</h6>
                                    <div className="ico_content mb-2">
                                        <PersonOutlineOutlined />{propertyDetails.user.name}
                                    </div>
                                    <div className="d-flex ico_content">
                                        <MailOutlined /> Email: {propertyDetails.user.email ? propertyDetails.user.email : 'N/A'} &nbsp;
                                        <LocalPhoneOutlined />Phone No: {propertyDetails.user.contact_number ? propertyDetails.user.contact_number : 'N/A'}
                                    </div>
                                </div>
                            )}
                            {propertyDetails?.property_manager && (
                                <div className="detail_block main_details_block">
                                    <h6>Property Manager’s Details</h6>
                                    <div className="ico_content mb-2">
                                        <PersonOutlineOutlined />{propertyDetails.property_manager?.name}
                                    </div>
                                    <div className="ico_content mb-2">
                                        <MailOutlined /> Email: {propertyDetails.property_manager.email ? propertyDetails.property_manager.email : 'N/A'}
                                    </div>
                                    <div className="ico_content mb-2">
                                        <LocalPhoneOutlined />Phone No: {propertyDetails.property_manager.contact_number ? propertyDetails.property_manager.contact_number : 'N/A'}
                                    </div>
                                </div>
                            )}

                            {(propertyDetails?.care_taker_contact_no || propertyDetails.care_taker_name) && (
                                <div className="detail_block social_contact_block">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="email_block contact_ico_content">
                                                <PersonOutlineOutlined />Care Taker Name :
                                                <p>{propertyDetails?.care_taker_name ? ucFirst(propertyDetails.care_taker_name) : 'N/A'}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="phone_block contact_ico_content">
                                                <LocalPhoneOutlined />Care Taker Phone No :
                                                <p>{propertyDetails?.care_taker_contact_no ? ucFirst(propertyDetails.care_taker_contact_no) : 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="detail_block owner_agreement_block">
                                <div className="uploadPictureContent">
                                    <h4 className="block_heading">Property’s Document</h4>
                                    {propertyDetails?.documents?.length > 0 ? (
                                        <FileList
                                            files={propertyDetails?.documents}
                                        />
                                    ) : <div >No documents are available for this property</div>}
                                </div>
                            </div>
                            <div className="detail_block amenityCustomBox py-0 pb-3 mb-3">
                                <h4 className="block_heading">Amenities</h4>
                                <ul className="custom_amenties_options d-flex flex-wrap ps-0 mb-0" style={{ listStyle: 'none' }}>
                                    {propertyDetails.amenities?.length > 0 ? propertyDetails?.amenities?.map((item) => (
                                        <li className="position-relative d-flex" key={item} style={{ fontSize: 'small' }}>
                                            {selectAmenitiesIcon(item.toLowerCase())}<div className="amenity_item">{item}</div>
                                        </li>
                                    )) : <div >No amenities are available for this property</div>}
                                </ul>
                            </div>
                            <div className="detail_block amenityCustomBox py-0">
                                <h4 className="block_heading">House Rules</h4>
                                <ul className="ps-0 mb-0">
                                    {propertyDetails.rules?.length > 0 ? propertyDetails?.rules?.map((item) => (
                                        <li className="house_item position-relative d-flex" key={item}>- {item}</li>
                                    )) : <div >No rules are available for this property</div>}
                                </ul>
                            </div>
                        </Card>
                    </div>
                    {isDocumentModal && (
                        <Modal
                            open={isDocumentModal}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={amenitiesStyle} className="tenantRemove resp_tenant_modal">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="crossIcon cursor-pointer position-absolute">
                                        <CloseOutlined onClick={handleClose} />
                                    </div>
                                </div>
                                <div className="amenityImagesSlider">
                                    <AmenitiesSlider files={documents} />
                                </div>
                            </Box>
                        </Modal>
                    )}
                </div>
            )}
        </div>
    )
}

export default BasicDetails;