import React, { useState, useEffect } from "react";
import {
    Card,
} from "reactstrap";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PdfIcon from "../../assets/images/pdfIcon.png";
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/material"
import * as Yup from "yup";
import { useFormik } from "formik";
import { SearchDropDownListPaginationComponent } from "../Common/SearchDropDownPaginationList";
import { ACTIONS, ALL_MODULES, KNOWN_ROLES, PROPERTY_TYPES, USER_STATUS } from "../../common/constant";
import { SelectDropDownListPaginationComponent } from "../Common/SelectDropDownPaginationList";
import { getAllUser } from "../../api/user";
import Link from '@mui/material/Link';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddAmenitiesModal from "./addAmenitiesModal";
import AddHouseRulesModal from "./addHouseRulesModal";
import { getAllNearTo } from "../../api/common";
import uuid from 'react-native-uuid';
import { endsWithAny, formatBytes } from "../../helpers/string_helper";
import ConfirmationModal from "../Common/ConfirmationModal";
import { selectAmenitiesIcon } from "../../common/amenities_icon";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Dropzone from "react-dropzone";
import { NearToDropDownPaginationList } from "../Common/NearToDropDownPaginationList";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { AsterikLabel } from '../Common/AsterikLabel';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
    setDefaults,
    fromPlaceId,
} from "react-geocode";
import { google } from "../../config";


export default function ActivePropertiesBasic({ emitBasicActiveData, backData, propertyData }) {
    const [isLoading, setIsLoading] = useState();
    const [selectPropertyOption, setSelectPropertyOption] = useState('manual')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHouseRulesModalOpen, setIsHouseRulesModalOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [refreshEdit, setRefreshEdit] = useState(0)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedOption, setSelectedOption] = useState('multiple');
    const [googleData, setGoogleData] = useState();
    const [isMounted, setIsMounted] = useState(false)
    const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
        status: false,
        data: null,
    });
    const [defaultImageUpdate, setDefaultImageUpdate] = useState(0)
    const basicPropertyObject = {
        name: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        district: '',
        state: '',
        country: '',
        pinCode: '',
        lat: '',
        long: '',
        nearTo: [],
        amenities: '',
        rules: [],
        ownerId: '',
        pictures: [],
        documents: [],
        defaultImage: '',
        total_documents_size: 0,
        total_picture_size: 0,
        type: '',
        careTaker: {
            name: '',
            contactNo: '',
            assignee: ''
        },
    }

    const handleChange = (event) => {
        if (event.target.value === selectedOption) {
            formik.setFieldValue('ownerId', null)
            setSelectedOption('multiple');
        } else {
            setSelectedOption('single');
        }

    };

    useEffect(() => {
        if (googleData?.value?.place_id && !isMounted) {
            setDefaults({
                key: google.API_KEY, // Your API key here.
                language: "en", // Default language for responses.
                region: "in", // Default region for responses.
            });

            fromPlaceId(googleData?.value?.place_id)
                .then(({ results }) => {
                    const { lat, lng } = results[0].geometry.location;
                    formik.setFieldValue('name', googleData?.value?.structured_formatting?.main_text)
                    formik.setFieldValue('addressLine1', googleData?.value?.structured_formatting?.secondary_text)
                    formik.setFieldValue('lat', lat)
                    formik.setFieldValue('long', lng)
                    results[0].address_components.reduce(
                        (acc, component) => {
                            if (component.types.includes("locality"))
                                formik.setFieldValue('city', component.long_name)
                            else if (component.types.includes("administrative_area_level_1")) {
                                acc.state = component.long_name;
                                formik.setFieldValue('state', component.long_name)
                            }
                            else if (component.types.includes("country")) {
                                acc.country = component.long_name;
                                formik.setFieldValue('country', component.long_name)
                            } else if (component.types.includes("postal_code")) {
                                acc.pinCode = component.long_name;
                                formik.setFieldValue('pinCode', component.long_name)
                            } else if (component.types.includes("administrative_area_level_3")) {
                                acc.district = component.long_name;
                                formik.setFieldValue('district', component.long_name)
                            }

                            return acc;
                        },
                        {}
                    );
                    setIsMounted(true)
                    setRefreshEdit(refreshEdit + 1)
                })
        }

    }, [googleData?.value])

    useEffect(() => {
        if (propertyData) {
            setSelectedOption(propertyData?.user ? 'single' : 'multiple')
            const data = {
                name: propertyData.name,
                addressLine1: propertyData.address.address_line_1,
                addressLine2: propertyData.address.address_line_2 ?? '',
                city: propertyData.address.city,
                district: propertyData.address?.district ?? '',
                state: propertyData.address.state,
                lat: propertyData?.latitude,
                long: propertyData?.longitude,
                country: propertyData.address.country,
                pinCode: propertyData.address.zip_code,
                defaultImage: propertyData?.default_image,
                nearTo: propertyData.near_to ? propertyData.near_to?.split(',') : [],
                amenities: propertyData.amenities?.map((am) => {
                    return { name: am, icon: selectAmenitiesIcon(am.toLowerCase()) }
                }),
                rules: propertyData.rules ?? [],
                ownerId: propertyData?.user ? { name: propertyData.user.name, id: propertyData.user.id, code: propertyData.user?.code } : '',
                pictures: propertyData.pictures ?? [],
                documents: propertyData.documents ?? [],
                type: propertyData.type,
                careTaker: {
                    name: propertyData.care_taker_name ?? '',
                    contactNo: propertyData.care_taker_contact_no ?? '',
                    assignee: propertyData?.property_manager
                        ? {
                            label: propertyData.property_manager.name, id: propertyData.property_manager.id, key: `${propertyData.property_manager.id}|${propertyData.property_manager.name}`
                        } : ''
                },

            }
            setSelectedDocuments(propertyData.documents)
            setSelectedFiles(propertyData.pictures)
            formik.setValues(data)
            setRefreshEdit(refreshEdit + 1)
        }
    }, [propertyData])

    const basicPropertyValidation = Yup.object().shape({
        name: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .max(250, "Too Long!")
            .required("Please enter property name"),
        addressLine1: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .max(250, "Too Long!")
            .required("Please enter address line 1 "),
        addressLine2: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .optional(),
        city: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .max(250, "Too Long!")
            .required("Please enter city"),
        district: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .max(250, "Too Long!"),
        state: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .max(250, "Too Long!")
            .required("Please enter state"),
        country: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .max(250, "Too Long!")
            .required("Please enter country"),
        pinCode: Yup.string()
            .strict(true)
            .trim('Must not include leading and trailing spaces')
            .matches(/^[0-9]{6}$/, 'Pin Code number must be 6 digits')
            .required("Please enter pin code"),
        defaultImage: Yup.string().required('Please upload default image'),
        lat: Yup.string().required('Latitude is required'),
        long: Yup.string().required('Longitude is required'),
        documents: Yup.array().max(10, 'You are only allowed to have 10 documents here.').optional(),
        total_documents_size: Yup.number().positive().when('documents', {
            is: (docs) => docs?.length > 0,
            then: (schema) => schema.min(1, 'The documents size must be greater than 1 Byte.').max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.').required(),
            otherwise: (schema) => schema.optional()
        }),
        pictures: Yup.array().max(10, 'You are only allowed to have 10 property pictures here.').optional(),
        total_picture_size: Yup.number().positive().when('pictures', {
            is: (docs) => docs?.length > 0,
            then: (schema) => schema.min(1, 'The pictures size must be greater than 1 Byte.').max(50 * 1024 * 1024, 'The pictures size must not exceed 50 MiB.').required(),
            otherwise: (schema) => schema.optional()
        }),
        nearTo: Yup.array().optional(),
        type: Yup.string()
            .required("Please select property type"),
        careTaker: Yup.object()
            .shape({
                name: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .optional(),
                contactNo: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
                    .optional(),
            })
            .optional()

    });

    useEffect(() => {
        if (backData) {
            formik.setValues(backData)
            setSelectedFiles(backData?.pictures)
            setSelectedDocuments(backData.documents)
            setSelectedOption(backData?.ownerId ? 'single' : 'multiple')
            emitBasicActiveData(backData, true, backData?.ownerId ? 'single' : 'multiple')
        }
    }, [backData])

    const formik = useFormik({
        initialValues: {
            ...basicPropertyObject,
        },
        validateOnMount: true,
        validationSchema: basicPropertyValidation
    });

    useEffect(() => {
        setUpdate(update + 1)
    }, [formik.values, selectedOption])

    const handleFileUpload = (files, name) => {
        setIsLoading(true);
        files.map((file) =>
            Object.assign(file, {
                uniqueId: uuid.v4(),
                location: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );

        if (name === 'pictures') {
            formik.setValues({
                ...formik.values,
                pictures: [...selectedFiles, ...files],
                total_picture_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
            }, true).then(() => {
                setSelectedFiles((prev) => {
                    return [...prev, ...files];
                })
            });
        } else {
            setSelectedDocuments((prev) => {
                return [...prev, ...files]
            })
            formik.setValues({
                ...formik.values,
                documents: [...selectedDocuments, ...files],
                total_documents_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
            })
        }

        setIsLoading(false);
    };

    useEffect(() => {
        emitBasicActiveData(formik.values, formik.isValid, selectedOption)
    }, [formik.isValid, update])

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAddAmenities = (data) => {
        formik.setValues({
            ...formik.values,
            amenities: data
        })
    }

    const handleAddHouseRules = (rules) => {
        formik.setValues({
            ...formik.values,
            rules,
        })
    }

    const handleRemoveHouseRule = (ruleToRemove) => {
        const updatedRules = formik.values?.rules?.filter(rule => rule !== ruleToRemove);
        formik.setValues({
            ...formik.values,
            rules: updatedRules
        })
    }


    const handleRemoveAmenities = (itemName) => {
        const updatedAmenities = formik.values.amenities?.filter((it) => it?.name !== itemName?.name)
        formik.setValues({
            ...formik.values,
            amenities: updatedAmenities
        })
    }

    function handleFileRemove(file, fileIndex, documentType) {
        setIsLoading(true)
        let data
        if (documentType === 'pictures') {
            data = formik.values.pictures
        } else {
            data = formik.values.documents
        }
        const newDocuments = data?.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId))

        if (documentType === 'pictures') {
            formik.setValues({
                ...formik.values,
                pictures: newDocuments,
                total_picture_size: selectedFiles.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)).reduce((p, c) => p + c.size, 0)
            })
            setSelectedFiles(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)))
        } else {
            formik.setValues({
                ...formik.values,
                documents: newDocuments,
                total_documents_size: selectedDocuments.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)).reduce((p, c) => p + c.size, 0)
            })
            setSelectedDocuments(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)))
        }

        setIsLoading(false);
        return true
    }

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: {
                        color: 'red', // Change the color here
                    },
                },
            },
        },
    });

    return (
        <Card className="p-3 activePropertyBasic">
            <div className="active_basic_details">
                <div className="single_input_block">
                    <h4 className="section_heading">Property Address</h4>
                    <div className="row mb-2">
                        <div className="col-md-4 col-12 mb-3">
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={selectPropertyOption}
                                    onClick={(event) => {
                                        !propertyData?.id ? formik.setFieldValue('name', '') : ''
                                        if (event.target.value === selectPropertyOption) {
                                            setSelectPropertyOption('manual')
                                        } else {
                                            setSelectPropertyOption('google')
                                        }
                                    }}
                                >
                                    <FormControlLabel value='google' control={<Radio />} label="Select Property to google map" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {selectPropertyOption === 'google' ? (
                            <div className="col-md-4 col-12 mb-3" style={{ zIndex: '9' }}>
                                <ThemeProvider theme={theme}>
                                    <GooglePlacesAutocomplete
                                        key={refreshEdit}
                                        query={{
                                            componentRestrictions: {
                                                latLngBounds: { north: '22.567', south: '88.367', },
                                                region: 'in',
                                                strictBounds: true,
                                            },
                                            fields: ["address_components", "geometry", "icon", "name"],
                                            types: ["establishment"],
                                            language: 'en',
                                        }}
                                        minLengthAutocomplete={4}
                                        getDefaultValue={() => formik?.values?.name}
                                        fetchDetails={true}
                                        isClearable={true}
                                        selectProps={{
                                            key: refreshEdit,
                                            defaultInputValue: formik?.values?.name,
                                            placeholder: <div>Select Property <AsterikLabel /></div>,
                                            name: "name",
                                            type: 'text',
                                            isClearable: true,
                                            required: true,
                                            onBlur: formik.handleBlur,
                                            googleData,

                                            onChange: (e) => {
                                                if (e === null) {
                                                    formik.setFieldValue('addressLine1', '')
                                                    formik.setFieldValue('lat', '')
                                                    formik.setFieldValue('long', '')
                                                    formik.setFieldValue('state', '')
                                                    formik.setFieldValue('city', '')
                                                    formik.setFieldValue('district', '')
                                                    formik.setFieldValue('country', '')
                                                    formik.setFieldValue('pinCode', '')
                                                    formik.setFieldValue('name', '')
                                                    formik.setFieldTouched('name', true, false)
                                                    setIsMounted(false)
                                                } else {
                                                    formik.setFieldTouched('name', false, false)
                                                }

                                                setGoogleData(e)
                                            }
                                        }}
                                        apiKey={google.API_KEY}
                                    />
                                </ThemeProvider>
                                <div>
                                    {formik.touched.name && formik.errors.name && (
                                        <span className="text-danger">{formik.errors.name}</span>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-4 col-12 mb-3" style={{ zIndex: '9' }}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Property"
                                    name="name"
                                    disabled={isLoading}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                                <div>
                                    {formik.touched.name && formik.errors.name && (
                                        <span className="text-danger">{formik.errors.name}</span>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="col-md-4 col-12 mb-3">
                            <ThemeProvider theme={theme}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Address Line 1"
                                    name="addressLine1"
                                    disabled={isLoading}
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                            </ThemeProvider>
                            <div>
                                {formik.touched.addressLine1 && formik.errors.addressLine1 && (
                                    <span className="text-danger">{formik.errors.addressLine1}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Address Line 2"
                                name="addressLine2"
                                disabled={isLoading}
                                value={formik.values.addressLine2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                className="w-100"
                            />
                            <div>
                                {formik.touched.addressLine2 && formik.errors.addressLine2 && (
                                    <span className="text-danger">{formik.errors.addressLine2}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <ThemeProvider theme={theme}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="City"
                                    name="city"
                                    disabled={isLoading}
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                            </ThemeProvider>
                            <div>
                                {formik.touched.city && formik.errors.city && (
                                    <span className="text-danger">{formik.errors.city}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="District"
                                name="district"
                                disabled={isLoading}
                                value={formik.values.district}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                className="w-100"
                            />
                            <div>
                                {formik.touched.district && formik.errors.district && (
                                    <span className="text-danger">{formik.errors.district}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <ThemeProvider theme={theme}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="State"
                                    name="state"
                                    disabled={isLoading}
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                            </ThemeProvider>
                            <div>
                                {formik.touched.state && formik.errors.state && (
                                    <span className="text-danger">{formik.errors.state}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <ThemeProvider theme={theme}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Country"
                                    name="country"
                                    disabled={isLoading}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                            </ThemeProvider>
                            <div>
                                {formik.touched.country && formik.errors.country && (
                                    <span className="text-danger">{formik.errors.country}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <ThemeProvider theme={theme}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Pin Code"
                                    name="pinCode"
                                    disabled={isLoading}
                                    value={formik.values.pinCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                            </ThemeProvider>
                            <div>
                                {formik.touched.pinCode && formik.errors.pinCode && (
                                    <span className="text-danger">{formik.errors.pinCode}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label={<div>Latitue <AsterikLabel /></div>}
                                name="lat"
                                disabled={isLoading}
                                value={formik.values.lat}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                className="w-100"
                            />
                            <div>
                                {formik.touched.lat && formik.errors.lat && (
                                    <span className="text-danger">{formik.errors.lat}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label={<div>Longitude <AsterikLabel /></div>}
                                name="long"
                                disabled={isLoading}
                                value={formik.values.long}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                className="w-100"
                            />
                            <div>
                                {formik.touched.long && formik.errors.long && (
                                    <span className="text-danger">{formik.errors.long}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <NearToDropDownPaginationList
                                disabled={isLoading}
                                searchEnabled={true}
                                name="nearTo"
                                listCall={getAllNearTo}
                                defaultValue={formik.values?.nearTo}
                                emitItem={(newValue) => {
                                    formik.setFieldValue('nearTo', newValue ? newValue : '')
                                }}
                                apiParams={{ limit: 10 }}
                            />
                            <div>
                                {formik.touched.nearTo && formik.errors.nearTo && (
                                    <span className="text-danger">{formik.errors.nearTo}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <Autocomplete
                                value={formik?.values?.type}
                                name="type"
                                disabled={!!propertyData}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('type', newValue)
                                }}
                                onBlur={() => {
                                    if (!formik.touched?.type) {
                                        formik.setFieldTouched("type", true, true);
                                    }
                                }}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        key={option}
                                        {...props}
                                    >
                                        {option}
                                    </Box>
                                )}
                                options={Object.values(PROPERTY_TYPES)}
                                renderInput={(params) => <ThemeProvider theme={theme}><TextField {...params} label="Select property type " size="small" required /></ThemeProvider>}
                            />
                            <div>
                                {formik.touched.type && formik.errors.type && (
                                    <span className="text-danger">{formik.errors.type}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 col-12 mb-3">
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={selectedOption}
                                    onClick={handleChange}
                                >
                                    <FormControlLabel value='single' control={<Radio />} label="Property belongs to single owner" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        {selectedOption === 'single' && (
                            <div className="col-md-4 col-12 mb-3">
                                <SearchDropDownListPaginationComponent
                                    // eslint-disable-next-line react/no-unescaped-entities
                                    label={<div>Select Property Owner's Name <AsterikLabel /></div>}
                                    searchEnabled={true}
                                    listCall={getAllUser}
                                    name="ownerId"
                                    onBlur={() => { formik.setFieldTouched('ownerId', true) }}
                                    defaultValue={formik.values?.ownerId ? formik.values.ownerId : null}
                                    emitItem={(newValue) => {
                                        if (newValue === undefined) {
                                            formik.setFieldValue("ownerId", '')
                                        }
                                        else {
                                            formik.setFieldValue(
                                                "ownerId",
                                                {
                                                    id: newValue?.id,
                                                    name: newValue?.key?.split('|')[1],
                                                    code: newValue?.code
                                                }
                                            );
                                        }
                                    }}
                                    apiParams={{
                                        filter_fields: ['role', 'status'],
                                        filter_inputs: [`${KNOWN_ROLES.PROSPECTING_OWNER},${KNOWN_ROLES.OWNER}`, USER_STATUS.ACTIVE],
                                    }}
                                />
                                <div>
                                    {formik.touched?.ownerId && formik.errors?.ownerId && (
                                        <span className="text-danger">{formik.errors?.ownerId}</span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="single_input_block">
                </div>
                <div className="uploadDocument py-3">
                    <div className="uploadDocumentHeader d-flex align-items-center mb-2">
                        <h5 className="me-2 mb-0">Official Documents and Files</h5>
                        <div className="uploadButton cursor-pointer">
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    handleFileUpload(acceptedFiles, 'documents')
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FileUploadOutlinedIcon /> Upload File
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>

                    <div className="uploadDocumentContent uploadPictureHeader">
                        <ul className="list-inline" style={{ listStyle: 'none', paddingTop: '5px' }}>
                            {formik?.values?.documents?.length > 0 && formik.values.documents.map((item, index) => (
                                <li key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
                                    {endsWithAny([".jpg", ".png", ".jpeg"], item?.name?.toLowerCase()) ? (
                                        <>
                                            <img src={item.location} alt={item.name} width="150" height="70" />
                                            <div className="text-truncate" style={{ width: '100px' }}>{item.name}</div>
                                            <CancelIcon color="error" onClick={() => setIsDocumentDeleteConfirm({
                                                status: true,
                                                data: { file: item, fileIndex: index, documentType: 'documents' }
                                            })} />
                                        </>
                                    ) : item?.name?.toLowerCase().endsWith(".pdf") ? (
                                        <>
                                            <img src={PdfIcon} alt={PdfIcon} style={{ height: '70px', objectFit: "contain" }} />
                                            <div className="text-truncate" style={{ width: '100px' }}>{item.name}</div>
                                            <CancelIcon onClick={() => setIsDocumentDeleteConfirm({
                                                status: true,
                                                data: { file: item, fileIndex: index, documentType: 'documents' }
                                            })} />
                                        </>
                                    ) : (
                                        <>
                                            <FileUploadOutlinedIcon className="me-1" />
                                            {item.name}
                                            <CancelIcon onClick={() => setIsDocumentDeleteConfirm({
                                                status: true,
                                                data: { file: item, fileIndex: index, documentType: 'documents' }
                                            })} />
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="single_input_block">
                    <div className="row">
                        <div className="col-md-4 col-12 mb-3">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Care-Taker Name"
                                name="careTaker.name"
                                disabled={isLoading}
                                value={formik.values?.careTaker?.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                className="w-100"
                            />
                            <div>
                                {formik.touched.careTaker?.name && formik.errors.careTaker?.name && (
                                    <span className="text-danger">{formik.errors.careTaker?.name}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Care-Taker Contact Number"
                                name="careTaker.contactNo"
                                disabled={isLoading}
                                value={formik.values?.careTaker?.contactNo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                className="w-100"
                            />
                            <div>
                                {formik.touched.careTaker?.contactNo && formik.errors.careTaker?.contactNo && (
                                    <span className="text-danger">{formik.errors.careTaker?.contactNo}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                            <SelectDropDownListPaginationComponent
                                label="Select Property Manager"
                                searchEnabled={false}
                                name="careTaker.assignee"
                                listCall={getAllUser}
                                defaultValue={formik.values?.careTaker?.assignee?.id ? formik.values?.careTaker?.assignee : null}
                                emitItem={(newValue) => {
                                    if (newValue === undefined) {
                                        formik.setFieldValue('careTaker.assignee', '')
                                    } else {
                                        formik.setFieldValue('careTaker.assignee', {
                                            id: newValue?.key?.split('|')[0],
                                            label: newValue?.key?.split('|')[1],
                                            key: newValue?.key
                                        })
                                    }
                                }}
                                apiParams={{
                                    filter_fields: ['propertyManager'],
                                    filter_inputs: [ALL_MODULES.PROPERTIES]
                                }}
                                onBlur={() => {
                                    if (!formik.touched?.careTaker?.assignee) {
                                        formik.setFieldTouched("careTaker.assignee", true, true);
                                    }
                                }}
                            />
                            <div>
                                {formik.touched.careTaker?.assignee && formik.errors.careTaker?.assignee && (
                                    <span className="text-danger">{formik.errors.careTaker?.assignee}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single_input_block">
                    <div className="uploadDocument py-3">

                        <div>
                            <label className="d-flex"> Upload Property Pictures <AsterikLabel /> </label>
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    handleFileUpload(acceptedFiles, 'pictures')
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="uploadButton" style={{ cursor: 'pointer' }}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FileUploadOutlinedIcon /> Upload File
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <span style={{ marginLeft: '4px', fontWeight: 'bold' }}>please set one picture as the default picture </span>
                        </div>

                        <div className="uploadDocumentContent uploadPictureContent" key={defaultImageUpdate}>
                            <ul>
                                {formik.values.pictures?.length > 0 && formik.values.pictures.map((item, index) => (
                                    <li key={index} className={formik.values.defaultImage?.name === item.name ? 'active' : ''}>
                                        <input
                                            type="radio"
                                            name="selectedItem"
                                            id={`item-${index}`}
                                            value={item.name}
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                formik.setFieldValue('defaultImage', item);
                                            }}
                                        />
                                        <div className={`single_priority_pic ${formik.values.defaultImage?.name === item.name ? 'checked' : ''}`}>
                                            <label htmlFor={`item-${index}`}>
                                                <img src={item.location} alt={item.name} width="150" height="70" />
                                                <div className="text-truncate">{item.name}</div>

                                                {formik.values.defaultImage?.name === item.name && !isDocumentDeleteConfirm.status && (<DoneIcon style={{ position: 'absolute', top: '-10px', right: "-10px", color: '#fff', fontSize: '10px', background: 'green', bordeRadius: '50%', height: '20px', padding: '3px', width: '20px', borderRadius: '50%' }} />)}

                                                {formik.values.defaultImage?.name !== item.name && formik.values.pictures.length > 1 && (<CancelIcon style={{ color: '#fb003a' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        setIsDocumentDeleteConfirm({
                                                            status: true,
                                                            data: { file: item, fileIndex: index, documentType: 'pictures' }
                                                        })
                                                    }} />)}
                                            </label>
                                        </div>
                                        {formik.values.defaultImage?.name === item.name && <p>This is the default image</p>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="single_input_block">
                    <div className="mb-2">
                        <Link href="#" underline="none" className="d-flex align-items-center"
                            onClick={() => {
                                setIsModalOpen(true)
                            }
                            } style={{ color: 'blue' }}>
                            <OtherHousesOutlinedIcon /> &nbsp; <span className="ms-1">Add Amenities</span>
                        </Link>
                    </div>
                    <ul className="custom_amenties_options d-flex flex-wrap ps-0" style={{ listStyle: 'none' }}>
                        {formik.values?.amenities?.length > 0 && formik.values.amenities?.map((item, index) => (
                            <li className="position-relative d-flex mx-1" key={index + 1}>
                                {item.icon} <div className="amenity_item">{item.name}</div>
                                <CancelOutlinedIcon color="error" className="cancelIcon" onClick={() => handleRemoveAmenities(item)} />
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="single_input_block">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Link href="#" className="d-flex align-items-center" underline="none"
                            onClick={() => setIsHouseRulesModalOpen(true)}
                            style={{ color: 'blue' }}>
                            <OtherHousesOutlinedIcon /> &nbsp; <span className="ms-1">Add House Rules</span>
                        </Link>
                    </div>
                    <ul className="custom_house_rules_options d-flex flex-wrap ps-0" style={{ listStyle: 'none' }}>
                        {formik.values?.rules?.length > 0 && formik.values?.rules.map((rule, index) => (
                            <li className="position-relative d-flex mx-1" key={index + 1}>
                                <div className="house_rule_item">{rule}</div>
                                <CancelOutlinedIcon color="error" className="cancelIcon" onClick={() => handleRemoveHouseRule(rule)} />
                            </li>
                        ))}
                    </ul>
                </div>
                {isModalOpen &&
                    <AddAmenitiesModal
                        open={isModalOpen}
                        handleClose={handleCloseModal}
                        handleAddAmenities={handleAddAmenities}
                        selectedAmenities={formik.values?.amenities} />}

                {isHouseRulesModalOpen &&
                    <AddHouseRulesModal
                        open={isHouseRulesModalOpen}
                        handleClose={() => setIsHouseRulesModalOpen(false)}
                        handleAddHouseRules={handleAddHouseRules}
                        selectedRules={formik.values?.rules}
                    />}


                <ConfirmationModal
                    action={ACTIONS.DELETE}
                    show={isDocumentDeleteConfirm?.status}
                    onCloseClick={() =>
                        setIsDocumentDeleteConfirm({ status: false, data: null })
                    }
                    onAcceptClick={async () => {
                        const { file, fileIndex, documentType } = isDocumentDeleteConfirm.data
                        const isDeleted = handleFileRemove(
                            file, fileIndex, documentType
                        );
                        if (isDeleted) {
                            setDefaultImageUpdate(defaultImageUpdate + 1)
                            setIsDocumentDeleteConfirm({ status: false, data: null });
                        }
                    }}
                    isDisabled={isLoading}
                />
            </div>
        </Card>
    )
}