import React, { useEffect, useState } from 'react';
import {
  Row,
  Button,
  Form,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  ACTIONS,
  DOCUMENT_HASH_BASE_KEYS,
  TENANT_TYPES,
  USER_STATUS,
  VERIFICATION_STATUS,
} from '../../common/constant';
import { Autocomplete, TextField, Box } from '@mui/material';
import {
  createTenantHandler,
  updateTenantHandler,
  uploadDocuments,
} from '../../api/Tenants/api';
import ConfirmationModal from '../Common/ConfirmationModal';
import {
  formatBytes,
  getS3BaseUrl,
  handleKeyDown,
} from '../../helpers/string_helper';
import Dropzone from 'react-dropzone';
import uuid from 'react-native-uuid';
import FileList from '../Common/FileList';
import FileUpload from '../../assets/images/icons/image-add-line.svg';
import { fileUploads } from '../../api/compay-employee';
import { getTenantDetails } from '../../api/tenant';
import { toast } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function AddTenantDrawer({ isOpen, toggleDrawer, refresh, action, tenantId }) {
  const [isAddTenant] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: null,
    data: null,
  });
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const [profilePhoto, setProfilePhoto] = useState(null);
  const s3BaseUrl = getS3BaseUrl();

  const tenantSchema = {
    type: tenantData?.type ?? '',
    guardian_name: tenantData?.guardian_name ?? '',
    guardian_contact_no: tenantData?.guardian_contact_no ?? '',
    local_guardian_name: tenantData?.local_guardian_name ?? '',
    local_guardian_contact_no: tenantData?.local_guardian_contact_no ?? '',
    police_verification: {
      status:
        tenantData?.police_verification?.status ??
        VERIFICATION_STATUS.INCOMPLETE,
    },
    agreement: {
      status: tenantData?.agreement?.status ?? VERIFICATION_STATUS.INCOMPLETE,
    },
    other_documents: tenantData?.other_documents ?? [],
    total_documents_size: tenantData?.total_documents_size,
    user: {
      name: tenantData?.user?.name ?? '',
      email: tenantData?.user?.email ?? '',
      status: tenantData?.user?.status ?? USER_STATUS.ACTIVE,
      contact_number: tenantData?.user?.contact_number ?? '',
      address: tenantData?.user?.address ?? '',
      blood_group: tenantData?.user?.blood_group ?? '',
      profile_photo: tenantData?.user?.profile_photo,
      profile_photo_size: tenantData?.user?.profile_photo_size,
    },
  };

  const tenantValidationSchema = Yup.object().shape({
    type: Yup.string().required('Please select a tenant type'),
    guardian_name: Yup.string().optional(),
    guardian_contact_no: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
      .optional(),
    local_guardian_name: Yup.string().optional(),
    local_guardian_contact_no: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
      .optional(),
    blood_group: Yup.string().optional(),
    police_verification: Yup.object()
      .shape({
        status: Yup.string().optional(),
      })
      .optional(),
    agreement: Yup.object('')
      .shape({
        status: Yup.string().optional(),
      })
      .optional(),
    other_documents: Yup.array()
      .max(10, 'You are only allowed to have 10 documents here.')
      .optional(),
    total_documents_size: Yup.number()
      .positive()
      .when('other_documents', {
        is: (docs) => docs?.length > 0,
        then: (schema) =>
          schema
            .min(1, 'The documents size must be greater than 1 Byte.')
            .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.')
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
    user: Yup.object().shape({
      name: Yup.string().required("Please enter tenant's name"),
      email: Yup.string().email('Email must be valid'),
      status: Yup.string().required("Please select tenant's status"),
      contact_number: Yup.string()
        .strict(true)
        .trim('Must not include leading and trailing spaces')
        .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
        .required('Please enter contact number'),
      profile_photo: Yup.string().nullable(),
      profile_photo_size: Yup.number()
        .positive()
        .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.'),
    }),
  });

  // The following properties will be implemented later
  // Password:'',other_documents: "",bed_rate: '',notes: '',

  // Form validation
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: tenantSchema,
    validationSchema: tenantValidationSchema,
    validateOnMount: true,
    onSubmit: async (tenantPayload) => {
      action === ACTIONS.EDIT
        ? setEditConfirm({
          status: true,
          id: tenantId,
          data: tenantPayload,
        })
        : setCreateConfirm({ status: true, data: tenantPayload });
    },
  });

  let isSuccess = null;
  const submitHandler = async (tenantFormData, id) => {
    setIsLoading(true);
    let documents;
    const filesUnchanged =
      formik.values?.other_documents?.filter((file) => file?.id) ?? [];
    if (filesUnchanged.length > 0) {
      documents = [...filesUnchanged.map((sd) => sd.id)];
    }
    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
    if (filesToBeUploaded.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f);
      });
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.TENANT);
      if (action === ACTIONS.CREATE) {
        formData.append('max_items', 10);
      }

      const result = await uploadDocuments(formData);
      if (result.success) {
        const { response } = result;
        if (documents !== undefined) {
          documents = [
            ...documents,
            ...response.data.data.documents.map((doc) => doc.id),
          ];
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)];
        }
      } else {
        return false;
      }
    }

    let imageURL = tenantFormData?.profile_photo;
    if (profilePhoto) {
      const profileImage = new FormData();
      profileImage.append('files', profilePhoto);
      profileImage.append('base_key', DOCUMENT_HASH_BASE_KEYS.TENANT);
      const result = await fileUploads(profileImage);
      if (result?.success) {
        imageURL = result?.response?.data?.data?.responses[0]?.actual_location;
      } else {
        return false;
      }
    }

    tenantFormData = {
      user: {
        name: tenantFormData.user.name,
        ...(tenantFormData.user.email && {
          email: tenantFormData.user.email,
        }),
        status: tenantFormData.user.status,
        contact_number: tenantFormData.user.contact_number,
        ...(tenantFormData.user.blood_group && {
          blood_group: tenantFormData.user.blood_group,
        }),
        profile_photo: imageURL,
      },
      ...(tenantFormData.type && {
        type: tenantFormData.type,
      }),
      ...(tenantFormData.guardian_name && {
        guardian_name: tenantFormData.guardian_name,
      }),
      ...(tenantFormData.guardian_contact_no && {
        guardian_contact_no: tenantFormData.guardian_contact_no,
      }),
      ...(tenantFormData.local_guardian_name && {
        local_guardian_name: tenantFormData.local_guardian_name,
      }),
      ...(tenantFormData.local_guardian_contact_no && {
        local_guardian_contact_no: tenantFormData.local_guardian_contact_no,
      }),
      ...(tenantFormData.police_verification && {
        police_verification: tenantFormData.police_verification,
      }),
      ...(tenantFormData.agreement && {
        agreement: tenantFormData.agreement,
      }),
      ...(action === ACTIONS.EDIT
        ? documents === undefined
          ? { other_documents: [] }
          : { other_documents: documents }
        : documents === undefined
          ? {}
          : { other_documents: documents }),
    };
    isSuccess =
      action === ACTIONS.EDIT
        ? await updateTenantHandler(id, tenantFormData)
        : await createTenantHandler(tenantFormData);
    setIsLoading(false);
    return isSuccess;
  };

  const handleClose = () => {
    toggleDrawer(!isOpen);
    formik.resetForm();
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik
      .setValues(
        {
          ...formik.values,
          other_documents: [...selectedFiles, ...files],
          total_documents_size: [...selectedFiles, ...files].reduce(
            (p, c) => p + c.size,
            0,
          ),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files];
        });
      });
  }

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.other_documents.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId),
    );
    const tempValues = {
      ...formik.values,
    };

    if (newDocuments.length === 0) {
      delete tempValues.other_documents;
      delete tempValues.total_documents_size;
    }
    formik
      .setValues(
        {
          ...tempValues,
          ...(newDocuments.length > 0 && {
            other_documents: newDocuments,
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) =>
                  !(i === fileIndex && doc.uniqueId === file.uniqueId),
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId),
          ),
        );
      });

    return true;
  }

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }

  const fetchTenantData = async () => {
    try {
      const response = await getTenantDetails(tenantId);
      if (response?.status === 200) {
        setTenantData(response?.data?.data);
        if (response?.data?.data?.other_documents?.length > 0) {
          const newDocuments = response?.data?.data?.other_documents?.map(
            (doc) => ({
              ...doc,
              uniqueId: uuid.v4(),
              formattedSize: formatBytes(doc.size),
            }),
          );
          setTenantData({
            ...response?.data?.data,
            profile_photo_size: 1,
            ...(newDocuments.length > 0 && {
              other_documents: [...newDocuments],
            }),
            ...(newDocuments.length > 0 && {
              total_documents_size: [...newDocuments].reduce(
                (p, c) => p + c.size,
                0,
              ),
            }),
          });
          setselectedFiles([...newDocuments]);
        }
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  useEffect(() => {
    document.body.className = 'add-tenant-bg';
    if (action === ACTIONS.EDIT) fetchTenantData();

    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  }, []);

  const addProfilePic = (file) => {
    let imageFile = file.target.files[0];
    Object.assign(imageFile, {
      uniqueId: uuid.v4(),
      location: URL.createObjectURL(imageFile),
      formattedSize: formatBytes(imageFile.size),
    });
    setProfilePhoto(imageFile);
    formik.setValues({
      user: {
        profile_photo: imageFile?.location,
        profile_photo_size: imageFile?.size,
      },
      ...formik.values,
    });
  };

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => {
        formik.resetForm();
        toggleDrawer(!isAddTenant);
      }}>
      <OffcanvasHeader toggle={toggleDrawer}>
        {action === ACTIONS.EDIT ? 'Edit Tenant' : 'Add New Tenant'}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}>
          <Row>
            {/* Profile Picture */}
            <div className='col-sm-12 mb-3'>
              <div className='custom-image-upload' style={{ alignItems: 'center' }}>
                <div className='image-placeholder-employee image-placeholder'>
                  <img
                    src={
                      profilePhoto
                        ? profilePhoto.location
                        : formik?.values?.user?.profile_photo
                          ? `${s3BaseUrl}${formik?.values?.user?.profile_photo}`
                          : FileUpload
                    }
                    alt='Not Found'
                  />
                </div>
                <div className='upload_picture'>
                  <Input
                    className='form-control pos-rel'
                    accept='image/*'
                    type='file'
                    id='user.profile_photo'
                    name='user.profile_photo'
                    style={{ display: 'none' }}
                    onClick={formik.handleBlur}
                    onChange={addProfilePic}
                    invalid={
                      !!(
                        formik.touched.user?.profile_photo &&
                        formik.errors.user?.profile_photo
                      )
                    }
                  />
                  <label
                    style={{
                      alignSelf: 'center',
                    }}
                    htmlFor='user.profile_photo'
                    className='custom-file-label mb-0 mx-2'>
                    Upload Tenant&apos;s Picture
                  </label>
                </div>
              </div>
              {formik.touched.user?.profile_photo &&
                formik.errors.user?.profile_photo ? (
                <span className='text-danger-formik'>
                  {' '}
                  {formik.errors.user?.profile_photo}
                  {formik.errors.user?.profile_photo_size}
                </span>
              ) : (
                ''
              )}
            </div>
            <br />

            {/* Name */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  name='user.name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.user?.name || ''}
                  error={
                    !!(formik?.touched?.user?.name && formik?.errors?.user?.name)
                  }
                  helperText={
                    formik?.touched?.user?.name && formik?.errors?.user?.name
                      ? formik?.errors?.user?.name
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Email ID */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Email ID'
                name='user.email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.user?.email || ''}
                error={
                  !!(formik.touched?.user?.email && formik.errors?.user?.email)
                }
                helperText={
                  formik.touched?.user?.email && formik.errors?.user?.email
                    ? formik.errors?.user?.email
                    : ''
                }
                size='small'
              />
            </div>

            {/* Tenant Status */}
            <div className='col-sm-6 '>
              <Autocomplete
                name='user.status'
                value={formik.values?.user?.status}
                onChange={(event, selecteValue) => {
                  formik.setFieldValue('user.status', selecteValue ?? '');
                }}
                onBlur={() => {
                  if (!formik.touched?.user?.status) {
                    formik.setFieldTouched('user.status', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(USER_STATUS)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={
                          !!(
                            formik?.touched?.user?.status &&
                            formik?.errors?.user?.status
                          )
                        }
                        helperText={
                          formik?.touched?.user?.status &&
                            formik?.errors?.user?.status
                            ? formik?.errors?.user?.status
                            : ''
                        }
                        label='Select Tenant Status'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
                size='small'
              />
            </div>

            {/* Contact Number */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Contact Number'
                  name='user.contact_number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.user?.contact_number || ''}
                  error={
                    !!(
                      formik.touched?.user?.contact_number &&
                      formik.errors?.user?.contact_number
                    )
                  }
                  helperText={
                    formik.touched?.user?.contact_number &&
                      formik.errors?.user?.contact_number
                      ? formik.errors?.user?.contact_number
                      : ''
                  }
                  size='small'
                  onWheel={(e) => e.target.blur()}
                  disabled={isLoading || action === ACTIONS.EDIT}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  required
                />
              </ThemeProvider>
            </div>

            {/* Tenant’s Blood Group */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Blood Group'
                name='user.blood_group'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.user?.blood_group || ''}
                error={
                  !!(
                    formik.touched?.user?.blood_group &&
                    formik.errors?.user?.blood_group
                  )
                }
                helperText={
                  formik.touched?.user?.blood_group &&
                    formik.errors?.user?.blood_group
                    ? formik.errors?.blood_group
                    : ''
                }
                size='small'
              />
            </div>

            {/* Tenant Type* */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='type'
                value={formik.values?.type}
                onChange={(event, selecteValue) => {
                  formik.setFieldValue('type', selecteValue);
                }}
                onBlur={() => {
                  if (!formik.touched?.type) {
                    formik.setFieldTouched('type', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) =>
                  TENANT_TYPES[option] === value
                }
                options={Object.values(TENANT_TYPES)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={!!(formik?.touched?.type && formik?.errors?.type)}
                        helperText={
                          formik?.touched?.type && formik?.errors?.type
                            ? formik?.errors?.type
                            : ''
                        }
                        label='Select Tenant Type'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
                size='small'
              />
            </div>

            {/* Guardian’s  Name */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Guardian Name'
                name='guardian_name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.guardian_name || ''}
                error={
                  !!(
                    formik.touched?.guardian_name &&
                    formik.errors?.guardian_name
                  )
                }
                helperText={
                  formik.touched?.guardian_name && formik.errors?.guardian_name
                    ? formik.errors?.guardian_name
                    : ''
                }
                size='small'
              />
            </div>

            {/* Guardian’s Contact Number */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Guardian’s Contact Number'
                name='guardian_contact_no'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.guardian_contact_no || ''}
                error={
                  !!(
                    formik.touched?.guardian_contact_no &&
                    formik.errors?.guardian_contact_no
                  )
                }
                helperText={
                  formik.touched?.guardian_contact_no &&
                    formik.errors?.guardian_contact_no
                    ? formik.errors?.guardian_contact_no
                    : ''
                }
                size='small'
                onWheel={(e) => e.target.blur()}
                disabled={isLoading}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
            </div>

            {/* Emergency Contact Person */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Emergency Contact Person'
                name='local_guardian_name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.local_guardian_name || ''}
                error={
                  !!(
                    formik.touched?.local_guardian_name &&
                    formik.errors?.local_guardian_name
                  )
                }
                helperText={
                  formik.touched?.local_guardian_name &&
                    formik.errors?.local_guardian_name
                    ? formik.errors?.local_guardian_name
                    : ''
                }
                size='small'
              />
            </div>

            {/* Emergency Contact Number */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Emergency Contact Number'
                name='local_guardian_contact_no'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.local_guardian_contact_no || ''}
                error={
                  !!(
                    formik.touched?.local_guardian_contact_no &&
                    formik.errors?.local_guardian_contact_no
                  )
                }
                helperText={
                  formik.touched?.local_guardian_contact_no &&
                    formik.errors?.local_guardian_contact_no
                    ? formik.errors?.local_guardian_contact_no
                    : ''
                }
                size='small'
                onWheel={(e) => e.target.blur()}
                disabled={isLoading}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
            </div>

            {/* Police Varification Status */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='police_verification.status'
                value={formik.values?.police_verification?.status}
                onChange={(event, newValue) => {
                  formik.setFieldValue('police_verification.status', newValue);
                }}
                onBlur={() => {
                  if (!formik.touched?.police_verification?.status) {
                    formik.setFieldTouched(
                      'police_verification.status',
                      true,
                      true,
                    );
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(VERIFICATION_STATUS)}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched?.police_verification?.status &&
                          formik.errors?.police_verification?.status
                        )
                      }
                      helperText={
                        formik.touched?.police_verification?.status &&
                          formik.errors?.police_verification?.status
                          ? formik.errors?.police_verification?.status
                          : ''
                      }
                      label='Select Police Verification Status'
                      size='small'
                    />
                  );
                }}
                size='small'
              />
            </div>
            {/* Agreement Status */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='agreement.status'
                value={formik.values?.agreement?.status}
                onChange={(event, newValue) => {
                  formik.setFieldValue('agreement.status', newValue);
                }}
                onBlur={() => {
                  if (!formik.touched?.agreement?.status) {
                    formik.setFieldTouched('agreement.status', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(VERIFICATION_STATUS)}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched?.agreement?.status &&
                          formik.errors?.agreement?.status
                        )
                      }
                      helperText={
                        formik.touched?.agreement?.status &&
                          formik.errors?.agreement?.status
                          ? formik.errors?.agreement?.status
                          : ''
                      }
                      label='Select Agreement Status'
                      size='small'
                    />
                  );
                }}
                size='small'
              />
            </div>

            {/* Other Docs */}
            <div className='col-12 mb-3'>
              <label>Upload Documents</label>
              <div className='col-12 mb-3'>
                {(formik.errors.other_documents ||
                  formik.errors.total_documents_size) && (
                    <ul>
                      {formik.errors.other_documents && (
                        <li className='text-danger'>
                          {formik.errors.other_documents}
                        </li>
                      )}
                      {formik.errors.total_documents_size && (
                        <li className='text-danger'>
                          {formik.errors.total_documents_size}
                        </li>
                      )}
                    </ul>
                  )}
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>

              {selectedFiles.length > 0 && (
                <FileList
                  files={selectedFiles}
                  confirmFileRemoval={confirmFileRemoval}
                />
              )}
            </div>
          </Row>

          <div className='formActions btn_right'>
            <Button
              className='yellow_gradient_btn'
              type='submit'
              disabled={!formik.isValid || isLoading}>
              Save & Continue
            </Button>
          </div>
        </Form>

        {/* Create confirmation modal */}
        {isCreateConfirm?.status && action === ACTIONS.CREATE &&
          <ConfirmationModal
            action={ACTIONS.CREATE}
            show={isCreateConfirm?.status}
            onCloseClick={() => setCreateConfirm({ status: false, data: null })}
            onAcceptClick={async () => {
              const isCreated = await submitHandler(isCreateConfirm?.data);
              if (isCreated && isSuccess) {
                setCreateConfirm({ status: false, data: null });
                await refresh();
                handleClose();
              } else {
                setCreateConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />}

        {/* Edit confirmation modal */}
        {isEditConfirm?.status && action === ACTIONS.EDIT &&
          <ConfirmationModal
            action={ACTIONS.EDIT}
            show={isEditConfirm?.status}
            onCloseClick={() =>
              setEditConfirm({ status: false, id: null, data: null })
            }
            onAcceptClick={async () => {
              const isUpdated = await submitHandler(
                isEditConfirm?.data,
                isEditConfirm?.id,
              );
              if (isUpdated && isSuccess) {
                await refresh();
                handleClose();
              }
              setEditConfirm({ status: false, id: null });
            }}
            isDisabled={isLoading}
          />}

        {/* Document Delete confirmation modal */}
        {isDocumentDeleteConfirm?.status &&
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />}
      </OffcanvasBody>
    </Offcanvas>
  );
}

AddTenantDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  refresh: PropTypes.func,
  tenantId: PropTypes.number,
  action: PropTypes.string,
};
export default AddTenantDrawer;
