import React, { useCallback, useEffect, useState } from 'react'
import { fetchAllReviews } from '../../../api/review';
import { toast } from 'react-toastify';
import { Avatar, Button, Card, CircularProgress, FormControl, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemText, OutlinedInput, Rating, Typography } from '@mui/material';
import { getS3BaseUrl } from '../../../helpers/string_helper';
import moment from 'moment/moment';
import NoDataIcon from "../../../assets/images/no-data.png";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Container } from 'reactstrap';
import { useProfile } from '../../Hooks/UserHooks';
import { addPropertyReviewReply, deleteComment, editComment } from '../../../api/comment';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import NodataFound from '../../Common/NoDataFound';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const StyledMenuItem = styled(MenuItem)`
&& {
  padding: 10px;
  /* Add more styles as needed */
}
`;
const Listbox = styled('ul')(
  ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 150px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
    };
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark'
      ? 'rgba(0,0,0, 0.50)'
      : 'rgba(0,0,0, 0.05)'
    };
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
);

const ListboxItem = styled('li')(`padding: '10px'`);

function PropertyReviews({ propertyId, reviewCompHeight }) {
  const { user } = useProfile();
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [meta, setMeta] = useState({});
  const [reply, setReply] = useState({});
  const [open, setOpen] = useState('');
  const [editReply, setEditReply] = useState({});
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getAllReviews = useCallback(
    async (limit, page, refresh = false) => {
      setIsLoading(true);
      const paramData = {
        limit,
        page,
      };
      try {
        const responseActivity = await fetchAllReviews(propertyId, paramData);
        refresh ? setReviews(responseActivity?.data?.data?.data || "") :
          setReviews((old) => [
            ...old,
            ...(responseActivity?.data?.data?.data || ""),
          ]);
        setMeta(responseActivity?.data?.data?.meta);
      } catch (err) {
        toast.error(err.message);
      }
      finally {
        setIsLoading(false);
      }
    },
    [limit, page],
  )
  useEffect(() => {
    getAllReviews(limit, page, true);
  }, []);

  const scrollHandler = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + clientHeight) < 2) {
      if (meta?.current_page < meta?.last_page) {
        getAllReviews(limit, page + 1);
        setPage((o) => o + 1);
      }
    }
  };

  const handleReply = async (id) => {
    const selectedReply = reply[id];
    try {
      const resp = await addPropertyReviewReply({ reply: selectedReply }, id);
      if (resp?.data?.message) {
        getAllReviews(limit, page, true);
        setReply((prev) => ({ ...prev, [id]: null }))
        toast.success(resp?.data?.message)
      }
    } catch (e) {
      if (e?.response?.data?.message) toast.error(e.response.data.message);
      else toast.error(e?.message);
    }
  }
  const handleCommentEdit = async (comment, comment_id) => {
    setEditReply((prev) => ({ ...prev, [comment_id]: { drawer: true, content: comment } }));
  }

  const updateCommentHandler = async (comment, comment_id) => {
    try {
      const resp = await editComment({ comment }, comment_id);
      if (resp?.data?.message) {
        getAllReviews(limit, page, true);
        setEditReply((prev) => ({ ...prev, [comment_id]: { drawer: false } }))
        toast.success(resp?.data?.message)
      }
    } catch (e) {
      if (e?.response?.data?.message) toast.error(e.response.data.message);
      else toast.error(e?.message);
    }
  }
  const handleCommentDelete = async (comment_id) => {
    try {
      const resp = await deleteComment(comment_id);
      if (resp?.data?.message) {
        getAllReviews(limit, page, true);
        toast.success(resp?.data?.message)
      }
    } catch (e) {
      if (e?.response?.data?.message) toast.error(e.response.data.message);
      else toast.error(e?.message);
    }
  }

  return (
    <div>
      <div
        style={{
          maxHeight: `${reviewCompHeight ? reviewCompHeight : 70
            }vh`,
          overflow: 'auto',
        }}
        onScroll={scrollHandler}
      >
        <Card >
          <div style={{
            margin: '8px 0px -9px 8px'
          }}>
            {reviews?.length === 0 && !isLoading && (
              <div style={{ textAlign: 'center' }}>
                <NodataFound text={`No reviews to show`} icon={NoDataIcon} />
              </div>
            )}
            {reviews?.length === 0 && isLoading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {reviews?.length > 0 && reviews.map((item, index) => (
              <li
                key={index}
                className="d-flex align-items-start"
              >
                <ListItem alignItems="flex-start" >
                  <ListItemAvatar>
                    <Avatar
                      alt={item?.commenter?.name}
                      src={`${getS3BaseUrl()}${item?.commenter?.profile_photo}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div className='d-flex flex-column review-comments'>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: 'text.primary', display: 'inline' }}
                        >
                          {item?.commenter?.name}
                        </Typography>
                        <div className='opacity-75'>
                          Local Guide
                        </div>
                        <span className='d-flex align-items-center'>
                          <Rating
                            className='me-2'
                            readOnly
                            sx={{ fontSize: "1rem" }}
                            value={item?.commenter?.tenant?.property_rating[0]?.rating}
                          />
                          <span className='opacity-75'>
                            {moment(item.created_at).utc().fromNow()}
                          </span>
                        </span>
                        <div className='opacity-75'>
                          {item?.content}
                        </div>
                      </div>
                    }
                    secondary={
                      <div className='mt-3'>
                        {item?.replies?.length > 0 && item?.replies.map((reply, index) =>
                          editReply[reply?.id]?.drawer ?
                            (<div key={index} className='review_replies mt-1 d-flex justify-content-between align-items-center'>
                              <FormControl
                                fullWidth
                                size='small'
                                className='edit-input'
                              >
                                <InputLabel htmlFor="reply">Edit reply</InputLabel>
                                <OutlinedInput
                                  id="edit-reply"
                                  size='small'
                                  label="Edit reply"
                                  value={editReply[reply?.id]?.content}
                                  onChange={(e) => { setEditReply((prev) => ({ ...prev, [reply.id]: { drawer: true, content: e.target.value } })) }}
                                />
                              </FormControl>
                              <FormControl
                                className='edit-button d-flex flex-row'
                              >
                                <IconButton
                                  size='small'
                                  variant='contained'
                                  disabled={editReply[reply?.id]?.content === reply.content}
                                  onClick={() => updateCommentHandler(editReply[reply?.id]?.content, reply.id)}
                                >
                                  <SendOutlinedIcon />
                                </IconButton>
                                <IconButton
                                  size='small'
                                  onClick={() => setEditReply((prev) => ({ ...prev, [reply.id]: { drawer: false, content: null } }))}
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              </FormControl>
                            </div>) :
                            (<div key={index} className='review_replies mt-1 d-flex justify-content-between align-items-center'>
                              {/* Reply */}
                              <div>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{ color: 'text.primary', display: 'inline' }}
                                >
                                  Response from {reply?.commenter?.name} ({reply?.commenter?.role?.role}) {moment(reply.created_at).utc().fromNow()}
                                </Typography>
                                <div>
                                  {reply?.content}
                                </div>
                              </div>

                              {/* Action Button */}
                              <span className='threeDotsSpace'>
                                <Dropdown>
                                  {reply?.commenter?.id && user?.id === reply?.commenter?.id && (<>
                                    <MenuButton>
                                      <i className='mdi mdi-dots-vertical font-size-15'></i>
                                    </MenuButton>
                                    <Menu slots={{ listbox: Listbox }}>
                                      <StyledMenuItem
                                        className='cursor-pointer'
                                        slots={{ ListboxItem: ListboxItem }}
                                        onClick={() => handleCommentEdit(reply?.content, reply?.id)}
                                      >
                                        <i className='mdi mdi-pencil-outline font-size-18 align-middle me-1 text-muted' />
                                        Edit
                                      </StyledMenuItem>
                                      <StyledMenuItem
                                        className='cursor-pointer'
                                        slots={{ ListboxItem: ListboxItem }}
                                        onClick={() => handleCommentDelete(reply?.id)}
                                      >
                                        <i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                        Delete
                                      </StyledMenuItem>
                                    </Menu>
                                  </>
                                  )}
                                </Dropdown>
                              </span>
                            </div>)
                        )}
                        {item?.replies?.length < 2
                          && (<div className='reply_input d-flex mt-2 align-items-center'>
                            <Container fluid>
                              <Accordion
                                open={open}
                                toggle={toggle}
                              >
                                <AccordionItem className='m-0'>
                                  <AccordionHeader targetId={item.id}>Reply</AccordionHeader>
                                  <AccordionBody accordionId={item.id}>
                                    <div className='d-flex align-items-center'>
                                      <FormControl
                                        fullWidth
                                        size='small'
                                      >
                                        <InputLabel htmlFor="reply">Add a reply</InputLabel>
                                        <OutlinedInput
                                          id="reply"
                                          size='small'
                                          label="Add a reply"
                                          onChange={(e) => { setReply((prev) => ({ ...prev, [item.id]: e.target.value })) }}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <Button
                                          size='small'
                                          variant='contained'
                                          className='yellow_gradient_btn ms-2'
                                          onClick={() => handleReply(item.id)}
                                        >
                                          POST
                                        </Button>
                                      </FormControl>
                                    </div>
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                            </Container>
                          </div>)}
                      </div>
                    }
                  />
                </ListItem>
              </li>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );

}

export default PropertyReviews