import React, { useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  //  NavLink, Col, Card,
} from 'reactstrap';
import { complainCreate, editComplainHandler } from '../../api/ComplainTicket/api';
import { searchProperties } from '../../api/complainTicket';
import {
  COMPLAIN_TICKET_PRIORITY,
  COMPLAIN_TICKET_STATUS,
  ACTIONS,
  DOCUMENT_HASH_BASE_KEYS,
} from '../../common/constant';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConfirmationModal from '../Common/ConfirmationModal';
import uuid from 'react-native-uuid';
import { formatBytes } from '../../helpers/string_helper';
import { uploadDocuments } from '../../api/CompanyAssociates/api';
import Dropzone from 'react-dropzone';
import FileList from '../Common/FileList';
import { getMembersOfProperties } from '../../api/property';
import { SelectDropDownListPaginationComponent } from '../Common/SelectDropDownPaginationList';
import { toast } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AsterikLabel } from '../Common/AsterikLabel';

function complainAddOwner({ isOpen, toggleDrawer, refresh, action, data }) {
  const [isAddExistingTenant] = useState(false);
  const [selectedFiles, setselectedFiles] = useState(data?.documents ?? []);
  const [availableOwners, setAvailableOwners] = useState([]);

  // formik
  const ownerSchema = {
    complain_details: data?.complain_details ?? '',
    contact_no: data?.contact_no ?? '',
    priority: data?.priority ?? '',
    status: data?.status ?? COMPLAIN_TICKET_STATUS.PENDING,
    user_id: data?.complainer_id,
    owner_name: data?.complained_by?.name ?? '', // only for autocomplete render values
    property_id: data?.property?.id ?? '',
    property: data?.property?.id
      ? {
        label: data?.property?.name, id: data?.property?.id, key: `${data?.property?.id}|${data?.property?.name}`
      } : '',
    // note: "",
    documents: data?.documents ?? [],
    ...(data?.documents?.length > 0 && {
      total_documents_size: data.documents.reduce(
        (p, c) => p + c.size,
        0,
      ),
    }),
  };

  const ownerValidationSchema = Yup.object().shape({
    complain_details: Yup.string().required('Please enter complain details'),
    contact_no: Yup.string().required('Please enter contact No.'),
    priority: Yup.string().required('Please select priority'),
    status: Yup.string().required('Please select status'),
    user_id: Yup.string().required('Please select owner'),
    property_id: Yup.string().required('Please select property'),
    documents: Yup.array()
      .max(10, 'You are only allowed to have 10 documents here.')
      .optional(),
    total_documents_size: Yup.number()
      .positive()
      .when('documents', {
        is: (docs) => docs?.length > 0,
        then: (schema) =>
          schema
            .min(1, 'The documents size must be greater than 1 Byte.')
            .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.')
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });
  const [isEditConfirm, setEditConfirm] = useState({
    id: null,
    data: null,
  });

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: ownerSchema,
    validationSchema: ownerValidationSchema,
    validateOnMount: true,
    onSubmit: async (ownerData) => {
      if (data?.id) {
        const payload = {
          complain_details: ownerData?.complain_details,
          contact_no: ownerData?.contact_no,
          priority: ownerData?.priority,
          status: ownerData?.status,
          // note: Joi.string().optional().label('note'),
          id: data.id,
        }
        setEditConfirm({ id: data?.id, data: payload })
      }
      else {
        setCreateConfirm({ status: true, data: ownerData });
      }
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  let isSuccess = null
  const submitHandler = async (ownerData) => {
    setIsLoading(true);
    let documents;
    const filesUnchanged =
      formik.values?.documents?.filter((file) => file?.id) ?? [];
    if (filesUnchanged.length > 0) {
      documents = [...filesUnchanged.map((sd) => sd.id)];
    }

    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
    if (filesToBeUploaded.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f);
      });
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.COMPLAIN_TICKET);
      if (action === ACTIONS.CREATE) {
        formData.append('max_items', 10);
      }

      const result = await uploadDocuments(formData);

      if (result.success) {
        const { response } = result;

        if (documents !== undefined) {
          documents = [
            ...documents,
            ...response.data.data.documents.map((doc) => doc.id),
          ];
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)];
        }
      } else {
        setIsLoading(false);
        return false;
      }
    }

    // removing empty fields
    ownerData = {
      ...(action === ACTIONS.EDIT && {
        id: data.id,
      }),
      contact_no: ownerData.contact_no,
      user_id: ownerData.user_id,
      property_id: ownerData.property_id,
      ...(ownerData.complain_details && {
        complain_details: ownerData.complain_details,
      }),
      ...(ownerData.priority && {
        priority: ownerData.priority,
      }),
      ...(ownerData.status && {
        status: ownerData.status,
      }),
      ...(action === ACTIONS.EDIT
        ? documents === undefined
          ? { documents: [] }
          : { documents }
        : documents === undefined
          ? {}
          : { documents }),
    };
    //
    isSuccess = action === ACTIONS.EDIT
      ? await editComplainHandler(ownerData)
      : await complainCreate(ownerData);
    setIsLoading(false);
    return isSuccess;
  };

  const handleClose = () => {
    toggleDrawer(!isOpen);
    setselectedFiles([]);
    formik.resetForm();
  };

  // file upload
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik
      .setValues(
        {
          ...formik.values,
          documents: [...selectedFiles, ...files],
          total_documents_size: [...selectedFiles, ...files].reduce(
            (p, c) => p + c.size,
            0,
          ),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files];
        });
      });
  }

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.documents.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId),
    );
    const tempValues = {
      ...formik.values,
    };

    if (newDocuments.length === 0) {
      delete tempValues.documents;
      delete tempValues.total_documents_size;
    }
    formik
      .setValues(
        {
          ...tempValues,
          ...(newDocuments.length > 0 && {
            documents: newDocuments,
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) =>
                  !(i === fileIndex && doc.uniqueId === file.uniqueId),
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId),
          ),
        );
      });

    return true;
  }

  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });
  // Fetch available owners upon property selection
  async function fetchAvailableOwners(property) {
    try {
      const response = await getMembersOfProperties({ properties: [property] });
      if (response.data.data[0].owners.length) {
        setAvailableOwners(response.data.data[0].owners);
      } else {
        toast.warning('Owner not found for selected property');
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => toggleDrawer(!isAddExistingTenant)}>
      <OffcanvasHeader toggle={toggleDrawer}>
        {data?.id ? 'Edit Owner\'s Complain Ticket' : 'Add Owner\'s Complain Ticket'}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}>
          <Row>
            {/* Select Property */}
            <div className='col-6 mb-3'>
              <SelectDropDownListPaginationComponent
                label={
                  <div>
                    Select Property <AsterikLabel />{' '}
                  </div>
                }
                disabled={action === ACTIONS.EDIT ? true : false}
                defaultValue={formik?.values?.property?.id ? formik.values?.property : null}
                name='property_id'
                searchEnabled={true}
                listCall={searchProperties}
                onBlur={() => formik.setFieldTouched('property_id', true)}
                emitItem={(newValue) => {
                  let id;
                  if (newValue) {
                    id = parseInt(newValue?.id);
                    fetchAvailableOwners({ id });
                  }
                  formik.setFieldTouched('property_id', true);
                  formik.setFieldValue('property_id', id, true); // Set the value in Formik
                }}
                error={
                  !!(formik.touched?.property_id && formik.errors?.property_id)
                }
                helperText={
                  formik.touched?.property_id && formik.errors?.property_id
                    ? formik.errors?.property_id
                    : ''
                }
                selectorSX={null}
              />
            </div>

            {/* Select Owner */}
            <div className='col-6 mb-3' onClick={() => {
              if (availableOwners.length === 0) toast.warning('Please select a property first.')
            }}>
              <Autocomplete
                name='user_id'
                value={formik.values.owner_name}
                onChange={(event, newValue) => {
                  formik.setFieldValue('user_id', newValue?.id);
                  formik.setFieldValue('owner_name', newValue?.name);
                  formik.setFieldValue('contact_no', newValue?.contact_number);
                }}
                onBlur={() => {
                  if (!formik.touched?.user_id) {
                    formik.setFieldTouched('user_id', true, true);
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component='li' key={option} {...props}>
                      {option.name}
                    </Box>
                  );
                }}
                options={availableOwners}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        error={
                          !!(formik.touched?.user_id && formik.errors?.user_id)
                        }
                        helperText={
                          formik.touched?.user_id && formik.errors?.user_id
                            ? formik.errors?.user_id
                            : ''
                        }
                        label='Select Owner'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
                disabled={availableOwners.length === 0}
                size='small'
              />
              {formik.touched.user_id && formik.errors.user_id && (
                <span className='text-danger'>{formik.errors.user_id}</span>
              )}
            </div>

            {/* Status */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='status'
                value={formik.values?.status}
                onChange={(event, newValue) => {
                  formik.setFieldValue('status', newValue);
                }}
                onBlur={() => {
                  if (!formik.touched?.status) {
                    formik.setFieldTouched('status', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(COMPLAIN_TICKET_STATUS)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        error={
                          !!(formik.touched?.status && formik.errors?.status)
                        }
                        helperText={
                          formik.touched?.status && formik.errors?.status
                            ? formik.errors?.status
                            : ''
                        }
                        label='Select Status'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
                size='small'
              />
            </div>

            {/* Priority */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='priority'
                value={formik.values?.priority}
                onChange={(event, newValue) => {
                  formik.setFieldValue('priority', newValue);
                }}
                onBlur={() => {
                  if (!formik.touched?.priority) {
                    formik.setFieldTouched('priority', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(COMPLAIN_TICKET_PRIORITY)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        error={
                          !!(
                            formik.touched?.priority && formik.errors?.priority
                          )
                        }
                        helperText={
                          formik.touched?.priority && formik.errors?.priority
                            ? formik.errors?.priority
                            : ''
                        }
                        label='Select Priority'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
                size='small'
              />
            </div>

            {/* Contact Number */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  name='contact_no'
                  label='Contact Number'
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  disabled
                  value={formik?.values?.contact_no}
                  error={
                    !!(
                      formik?.touched?.contact_no && formik?.errors?.contact_no
                    )
                  }
                  helperText={
                    formik?.touched?.contact_no && formik?.errors?.contact_no
                      ? formik?.errors?.contact_no
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Complain details  */}
            <div className='col-12 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  multiline
                  rows={7}
                  name='complain_details'
                  label='Complain Details'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.complain_details}
                  error={
                    !!(
                      formik?.touched?.complain_details &&
                      formik?.errors?.complain_details
                    )
                  }
                  helperText={
                    formik?.touched?.complain_details &&
                      formik?.errors?.complain_details
                      ? formik?.errors?.complain_details
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* file upload */}
            <div className='col-12 mb-3'>
              <label>Upload Documents</label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>

              {selectedFiles.length > 0 && (
                <FileList
                  files={selectedFiles}
                  confirmFileRemoval={confirmFileRemoval}
                />
              )}
            </div>
          </Row>
          <div className='formActions btn_right'>
            <Button
              className='yellow_gradient_btn'
              type='submit'
              disabled={!formik.isValid || isLoading}>
              Save
            </Button>
          </div>
        </Form>
        {isCreateConfirm?.status &&
          <ConfirmationModal
            action={ACTIONS.CREATE}
            show={isCreateConfirm?.status}
            onCloseClick={() => setCreateConfirm({ status: false, data: null })}
            onAcceptClick={async () => {
              const isCreated = await submitHandler(isCreateConfirm?.data);
              setCreateConfirm({ status: false, id: null });
              if (isCreated && isSuccess) {
                await refresh();
                handleClose();
              }

            }}
            isDisabled={isLoading}
          />}

        {/* Edit confirmation modal */}
        {isEditConfirm.id &&
          <ConfirmationModal
            action={ACTIONS.EDIT}
            show={isEditConfirm.id}
            onCloseClick={() => setEditConfirm({ data: null, id: null })}
            onAcceptClick={async () => {
              const isEdited = await submitHandler(isEditConfirm.data);
              setEditConfirm({ id: null, data: null });
              if (isEdited && isSuccess) {
                await refresh();
                handleClose();
              }
            }}
            isDisabled={isLoading}
          />}

        {/* Document Delete confirmation modal */}
        {isDocumentDeleteConfirm?.status &&
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                await refresh();
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />}
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default complainAddOwner;
