import React, { useEffect, useState } from 'react';
import {
  TabContent,
  TabPane,
  // Collapse,
  NavLink,
  NavItem,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  // CardTitle,
  Container,
} from 'reactstrap';
import classnames from 'classnames';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import NoticePeriod from '../../components/GlobalSettings/noticePeriod';
import DepositAmount from '../../components/GlobalSettings/depositAmount';
import Agreement from '../../components/GlobalSettings/agreement';
import Electricity from '../../components/GlobalSettings/electricity';
import Reminders from '../../components/GlobalSettings/reminders';
import CMS from '../../components/GlobalSettings/CMS/index';
import { listApi } from '../../api/GlobalSettings/api';
import PropertyListSorting from '../../components/GlobalSettings/propertyListPreference';
import { useProfile } from '../../components/Hooks/UserHooks';
import { ROLES } from '../../constants/variables';

export default function GlobalSettings() {
  const [activeTab, setactiveTab] = useState({});
  const [tabs, setTabs] = useState([]);
  const isSuperAdmin = useProfile().user.role.role === ROLES.SUPER_ADMIN;

  function toggle(tab) {
    if (activeTab.id !== tab.id) {
      setactiveTab({...tab, isEditable: isSuperAdmin});
    }
  }

  const fetchData = async () => {
    try {
      const res = await listApi();
      setTabs(res.data);
      setactiveTab(res.data[0]);
    } catch (error) {
      // Handle error if needed
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  return (
    < div style={{ minHeight: '100vh' }} className="page-content">
      <Container fluid className="no_breadcrumbs_holder">
        <Breadcrumbs breadcrumbItem="Global Settings" />

        <Row>
          <Col xl={3}>
            <Card>
              <CardBody>
                <Nav pills className="flex-xl-column">
                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      <span>Notice Period</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '2',
                      })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      <span>Deposit Amount</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '3',
                      })}
                      onClick={() => {
                        toggle('3');
                      }}
                    >
                      <span>Agreement & Police Verification Charge</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '8',
                      })}
                      onClick={() => {
                        toggle('8');
                      }}
                    >
                      <span>Canteen Food Rate</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '4',
                      })}
                      onClick={() => {
                        toggle('4');
                      }}
                    >
                      <span>Electricity Charge per Unit</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '10',
                      })}
                      onClick={() => {
                        toggle('10');
                      }}
                    >
                      <span>Reminders</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '11',
                      })}
                      onClick={() => {
                        toggle('11');
                      }}
                    >
                      <span>CMS</span>
                    </NavLink>
                  </NavItem> */}

                  {tabs.map(tab => {
                    return (
                      <NavItem key={tab.id}>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: activeTab?.name === tab.name,
                          })}
                          onClick={() => {
                            toggle(tab);
                          }}
                        >
                          <span>{tab.display_name}</span>
                        </NavLink>
                      </NavItem>
                    )
                  })}
                </Nav>
              </CardBody>
            </Card>
          </Col>

          <Col xl={9}>
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab.name} className="globalSettingsSection">
                  <TabPane tabId="platform_fee">
                    {activeTab.name === 'platform_fee' && <NoticePeriod tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="notice_period">
                    {activeTab.name === 'notice_period' && <NoticePeriod tabDetails={activeTab} />}
                  </TabPane>
                  
                  <TabPane tabId="deposit_amount">
                    {activeTab.name ==='deposit_amount' && <DepositAmount tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="agreement_police_verification_charge">
                    {activeTab.name === 'agreement_police_verification_charge' && <Agreement tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="electricity_charge_per_unit">
                    {activeTab.name === 'electricity_charge_per_unit' && <Electricity tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="property_list_sorting_preference">
                    {activeTab.name === 'property_list_sorting_preference' && <PropertyListSorting tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="payout_percentage">
                    {activeTab.name === 'payout_percentage' && <NoticePeriod tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="pre_booking_days">
                    {activeTab.name === 'pre_booking_days' && <NoticePeriod tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="cancellation_charge">
                    {activeTab.name === 'cancellation_charge' && <NoticePeriod tabDetails={activeTab} />}
                  </TabPane>

                  <TabPane tabId="10">
                    <p className="mb-0">
                      <Reminders />
                    </p>
                  </TabPane>

                  <TabPane tabId="11">
                    <p className="mb-0">
                      <CMS />
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
