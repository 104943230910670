import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getAllProspectingOwner } from '../../../api/owner';
import PaginationComponent from '../../../components/Common/PaginationComponent';
import { ACTIONS, ALL_MODULES, DEFAULT_PER_PAGE_VALUE, OPERATION_TYPE } from '../../../common/constant';
import Search from '../../../components/Common/GlobalSearch';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../../components/Common/NoDataFound';
import PropertyOwnerFilter from '../../../components/PropertyOwner/propertyOwnerFilterDrawer';
import AddPropertyOwnerDrawer from '../../../components/PropertyOwner/AddPropertyOwnerDrawer';
import TableHeaders from '../../../components/Common/TableHeaders';
import PropertyOwnerDetailDrawer from '../../../components/PropertyOwner/PropertyOwnerDetailDrawer';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';
import { deletePropertyOwnerHandler } from '../../../api/PropertyOwner/api';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Dropdown } from '@mui/base/Dropdown';
import { useLocation } from 'react-router-dom';
import NoDataIcon from "../../../assets/images/no-data.png";
import { usePermissionGiven } from '../../../components/Hooks/UserPermission';
import { handlePasswordResetLink } from '../../../api/User/reset';

function ProspectingOwner() {
  document.title = ' Prospecting Owners | Dstayz';

  const [paginationMeta, setPaginationMeta] = useState({});
  const [searchItem, setSearchItem] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [addPropertyOwnerDrawer, setAddPropertyOwnerDrawer] = useState(false);
  const [isOwnerFilter, setIsOwnerFilter] = useState(false);
  const [ownerList, setOwnerList] = useState([]);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const [sort, setSort] = useState({});
  const [detailShowId, setDetailShowId] = useState(useLocation()?.state?.id);
  const [ownerId, setOwnerId] = useState(null);
  const hasCreatePermission = usePermissionGiven(ALL_MODULES.OWNERS, OPERATION_TYPE.CREATE);
  const hasEditPermission = usePermissionGiven(ALL_MODULES.OWNERS, OPERATION_TYPE.UPDATE);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.OWNERS, OPERATION_TYPE.DELETE);
  const [isSendConfirm, setIsSendConfirm] = useState({ status: false, id: null })


  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 150px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );
  const ListboxItem = styled('li')(
    `padding: '10px'`
  )

  const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 10px;
    /* Add more styles as needed */
  }
`;

  const ownerListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllProspectingOwner(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      return resultData;
    } catch (error) {
      if(error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllPropertyOwnerList = async (
    searchItem = '',
    paginatedData = { page, limit: rowsPerPage },
  ) => {
    const params = {
      ...paginatedData,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    };
    const resultData = await ownerListApi(params);
    setOwnerList(resultData);
  };

  const globalSearch = async (params) => {
    setOwnerList([]);
    setSearchItem(params);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    const resultData = await ownerListApi({
      ...params,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setOwnerList(resultData);
  };

  const paginationModelMethod = async (data) => {
    let params = {
      ...(searchItem?.search && {
        ...searchItem,
      }),
    };

    if (data?.page) {
      setPage(data.page);
      params.page = data.page;
      fetchAllPropertyOwnerList(params, {
        page: data.page,
        limit: rowsPerPage,
      });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setRowsPerPage(data?.limit);
      params.limit = data.limit;
      fetchAllPropertyOwnerList(params, {
        page: data.page,
        limit: data?.limit,
      });
    }
  };

  const filter = async (filterFields, filterInputs) => {
    setOwnerList([]);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    setFilterInputs(filterInputs);
    setFilterFields(filterFields);
    const resultData = await ownerListApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setOwnerList(resultData);
  };

  const refresh = async () => {
    setOwnerList([]);
    setOwnerId(null);
    setPage(1);
    const resultData = await ownerListApi({
      limit: rowsPerPage,
      page: 1,
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setOwnerList(resultData);
    // updateStats();
  };

  const handleSort = async (sort) => {
    setOwnerList([]);
    setSort(sort);
    const resultData = await ownerListApi({
      // Remaining sustainable params
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setOwnerList(resultData);
  };

  // Add new owner drawer
  const toggleAddPropertyOwner = () => {
    if (addPropertyOwnerDrawer) {
      setOwnerId(null);
    }
    setAddPropertyOwnerDrawer(!addPropertyOwnerDrawer);
  };

  // Update onwer drawer handler
  const editOwnerAction = async (id) => {
    setOwnerId(id);
    setAddPropertyOwnerDrawer(true);
  };

  // Delete confirmation modal
  const deleteHandler = async (id) => {
    setDeleteConfirm({ status: true, id });
  };

  // owner filter drawer
  const toggleDrawer = () => {
    setIsOwnerFilter(!isOwnerFilter);
  };

  const columns = [
    {
      column_name: 'Prospecting Owner Id',
      key_name: 'code',
      sort_enabled: false,
      style: { minWidth: '125px' },
    },
    {
      column_name: 'Owner Name',
      key_name: 'name',
      sort_enabled: true,
      style: { minWidth: '125px' },
    },
    {
      column_name: 'Owner Email',
      key_name: 'email',
      sort_enabled: false,
      style: { minWidth: '145px' },
    },
    {
      column_name: 'Owners Contact No.',
      key_name: 'contact_number',
      sort_enabled: true,
      style: { minWidth: '170px' },
    },
    {
      column_name: 'Occupation',
      key_name: 'occupation',
      sort_enabled: true,
      style: { minWidth: '210px' },
    },
  ];

  useEffect(() => {
    fetchAllPropertyOwnerList();
  }, []);
  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row>
            <Col className='col-12'>
              <div className='flexbox_holder'>
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: rowsPerPage,
                      page: 1,
                      ...(sort?.order &&
                        sort?.direction && 
                        {
                          order: sort?.order,
                          direction: sort?.direction,
                        }),
                      ...(filterFields &&
                          filterInputs &&
                          {
                            filter_fields: filterFields,
                            filter_inputs: filterInputs,
                          })
                    }}
                  />
                </div>

                <div>
                  <Button
                    className='yellow_gradient_btn me-2'
                    onClick={toggleDrawer}>
                    <i className='mdi mdi-filter-variant' />
                    Filter
                  </Button>
                  {hasCreatePermission ?
                    (<Button color='primary' onClick={toggleAddPropertyOwner}>
                      <i className='mdi mdi-plus' />
                      Owner
                    </Button>) : ""}
                </div>
              </div>
              <Card className='table_card_holder'>
                <CardBody>
                  <div className='table-rep-plugin'>
                    <div
                      className='table-responsive mb-0'
                      data-pattern='priority-columns'>
                      {ownerList.length === 0 && isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                      {ownerList.length === 0 && !isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <NodataFound text='No prospecting owner to show' icon={NoDataIcon} />
                        </div>
                      )}
                      {ownerList.length > 0 && (
                        <Table
                          id='tech-companies-1'
                          className='table table-bordered'>
                          <TableHeaders
                            headers={columns}
                            sortBy={sort}
                            emitSortedColumn={handleSort}
                            module={ALL_MODULES.OWNERS}
                          />
                          <Tbody>
                            {ownerList &&
                              ownerList?.map((owner, index) => (
                                <Tr key={index}>
                                  <Td>{owner.indexNo}</Td>
                                  <Td>{owner?.user?.code}</Td>
                                  <Td className='cursor_pointer' onClick={() => setDetailShowId(owner.id)}>
                                    {owner?.user?.name ?? 'N/A'}
                                  </Td>
                                  <Td>{owner?.user?.email}</Td>
                                  <Td>{owner?.user?.contact_number}</Td>
                                  <Td>{owner?.occupation}</Td>
                                  {(hasEditPermission || hasDeletePermission) &&
                                    (<Td className="threeDotsSpace">
                                    <Dropdown>
                                      <MenuButton><i className='mdi mdi-dots-vertical font-size-15'></i></MenuButton>
                                      <Menu slots={{ listbox: Listbox }}>
                                        {hasEditPermission &&
                                          (<StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }}
                                            onClick={() => editOwnerAction(owner?.id)
                                            }>
                                            <i className='mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1' />
                                            Edit
                                          </StyledMenuItem>)}
                                        {hasEditPermission &&
                                          !owner?.user?.is_password_reset &&
                                            (<StyledMenuItem className="cursor-pointer" 
                                              slots={{ ListboxItem: ListboxItem }}
                                              onClick={() => setIsSendConfirm({status: true, id: owner?.user?.id})}>
                                              <i className="mdi mdi-message-draw font-size-18 align-middle text-muted me-1" />
                                              Send Invite
                                            </StyledMenuItem>)}
                                        {hasDeletePermission &&
                                          (<StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() =>
                                            deleteHandler(owner?.id)
                                          }><i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                            Delete
                                          </StyledMenuItem>)}
                                      </Menu>
                                    </Dropdown>
                                  </Td>)}
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      )}
                    </div>
                  </div>

                  {ownerList.length > 0 && (
                    <div className='numbers_pagination_holder'>
                      <PaginationComponent
                        paginationMeta={paginationMeta}
                        handleChangePagination={paginationModelMethod}
                        update={update}></PaginationComponent>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {detailShowId &&
        <PropertyOwnerDetailDrawer
          ownerId={detailShowId}
          refresh={refresh}
          isOpen={!!detailShowId}
          setIsOpen={setDetailShowId}
        >
        </PropertyOwnerDetailDrawer>}

      {
        addPropertyOwnerDrawer &&
        (
          <AddPropertyOwnerDrawer
            isOpen={addPropertyOwnerDrawer}
            toggleDrawer={toggleAddPropertyOwner}
            refresh={refresh}
            ownerId={ownerId}
            action={ownerId ? ACTIONS.EDIT : ACTIONS.CREATE}
          />
        )
      }
      {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
        action={ACTIONS.DELETE}
        show={isDeleteConfirm.status}
        onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isDeleted = await deletePropertyOwnerHandler(
            isDeleteConfirm.id,
          );
          if (isDeleted) {
            refresh();
          }
          setDeleteConfirm({ status: false, id: null });
        }}
        isDisabled={isLoading}
      />}

      {isOwnerFilter &&
        <PropertyOwnerFilter
        isOpen={isOwnerFilter}
        toggleDrawer={toggleDrawer}
        emitFilterItem={filter}
        selectedFields={filterFields}
        selectedInputs={filterInputs}
      />}

      {isSendConfirm.status &&
        <ConfirmationModal
        text={'send'}
        show={isSendConfirm.status}
        onCloseClick={() => setIsSendConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isSend = await handlePasswordResetLink(isSendConfirm.id);
          if (isSend) {
            setIsLoading(false)
          }
          setIsSendConfirm({ status: false, id: null });
        }}
        isDisabled={isLoading}
      />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(ProspectingOwner);
