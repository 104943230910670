import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
} from "reactstrap";
import ActivityTimeline from "../ActiveProperties/ActivePropertiesDetail/activityTimeline";
import { ALL_MODULES, BILL_PAID_STATUS, OPERATION_TYPE, VACANT_REQUEST_ACKNOWLEDGE_STATUS } from "../../common/constant";
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import dayjs from "dayjs";
import moment from "moment";
import { InitiateRefundAmount } from "./RefundInitiate";
import { usePermissionGiven } from "../Hooks/UserPermission";
import { getAllGlobalSettings } from "../../api/globalSettings";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { detailValue } = props;
  //const { propertyType: detailValue} = useParams();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>
            {React.cloneElement(children, { detailValue })}
          </Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function PGVacantRequestDetailsViewer({ isOpen, toggleDrawer, selectedRow, reqId, handleStatusChange }) {
  const [vacantData, setVacantData] = useState();
  const [value, setValue] = useState(0);
  const [anchorElPaid, setAnchorElPaid] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isRefundModal, setIsRefundModal] = useState(false);
  const handleOpenRefundModal = () => setIsRefundModal(true);
  const handleCloseRefundModal = () => setIsRefundModal(false);
  const hasPaymentPermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.DEPOSIT_PAYMENT)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleClickForPaid = (event) => {
    setAnchorElPaid(event.currentTarget);
  };

  useEffect(() => {
    fetchGlobalSettings()
  }, [selectedRow])

  const fetchGlobalSettings = async () => {
    try {
      const response = (await getAllGlobalSettings({ search: 'platform_fee' })).data.data
      setVacantData({
        ...selectedRow,
        platform_fee: parseInt(response.data[0].payload?.value)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleCloseForPaid = () => {
    setAnchorElPaid(null);
  };
  const showInfoForPayment = Boolean(anchorElPaid);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={toggleDrawer}>
      <OffcanvasBody>
        <div className="active-property-main-detail">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="aPTabs"
              >
                <Tab label="Basic Details" {...a11yProps(0)} />
                <Tab label="Activity Timeline" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Form>
                <OffcanvasHeader
                  toggle={toggleDrawer}
                  style={{
                    marginTop: "-3vh",
                    marginLeft: "-1vw",
                    padding: '10px'
                  }}
                >
                </OffcanvasHeader>
                <div className="tenantDetailsDrawer">
                  <Row className="mb-3">
                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Property Name: </div>
                      <div className="tenantDetailDesc">
                        {vacantData?.property_name}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Tenant name:</div>
                      <div className="tenantDetailDesc">
                        {vacantData?.tenant_name}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Bed Type: </div>
                      <div className="tenantDetailDesc">
                        {vacantData?.bed_type}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Room Type:</div>
                      <div className="tenantDetailDesc">
                        {vacantData?.room_type}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Monthly rent: </div>
                      <div className="tenantDetailDesc">
                        {vacantData?.monthly_rent}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Joining Date: </div>
                      <div className="tenantDetailDesc">
                        {vacantData?.joining_date}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Leaving Date:</div>
                      <div className="tenantDetailDesc">{vacantData?.leaving_date}</div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">
                        Acknowledgement Status:
                      </div>
                      <div className="tenantDetailDesc">
                        {vacantData?.acknowledge_status}
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Refund Amount: </div>
                      <div className="tenantDetailDesc">{vacantData?.refund_amount}</div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle">Rent Payment Status: </div>
                      <div className="tenantDetailDesc">{vacantData?.payment_history?.status ?? 'Pending'}
                        {vacantData?.payment_history?.status === BILL_PAID_STATUS.PAID && (
                          <><InfoIcon onClick={handleClickForPaid} className='ms-2' /><Popover
                            className='popup_block_info'
                            id={vacantData?.id}
                            open={showInfoForPayment}
                            anchorEl={anchorElPaid}
                            onClose={handleCloseForPaid}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Typography sx={{ p: 2 }} className='popContent'>
                              <p>Payment Date: {dayjs(vacantData?.payment_history?.payment_date).format('DD-MM-YYYY')}</p>
                              <p>Transaction ID: {vacantData?.payment_history?.transaction_id}</p>
                            </Typography>
                          </Popover></>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-12 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle tenantDetFullTitle">Current Month Total Payable Amount: </div>
                      <div className="tenantDetailDesc">{vacantData?.tenant_rent_slip ? (vacantData?.tenant_rent_slip?.rent + vacantData?.tenant_rent_slip?.electricity_amount + vacantData?.tenant_rent_slip?.other_amount + vacantData?.platform_fee) : 0}
                        <InfoIcon onClick={handleClick} className='ms-2' />
                        <Popover
                          className='popup_block_info'
                          id={vacantData?.id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2 }} className='popContent'>
                            <p>Rent: {vacantData?.tenant_rent_slip?.rent ?? 0}</p>
                            <p>Electricity Amount: {vacantData?.tenant_rent_slip?.electricity_amount ?? 0}</p>
                            <p>Other Amount: {vacantData?.tenant_rent_slip?.other_amount ?? 0}</p>
                            <p>Platform Fee: {vacantData?.platform_fee ?? 0}</p>
                          </Typography>
                        </Popover>
                      </div>
                    </div>

                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle tenantDetLeavingReason">
                        Leaving Reason:
                      </div>
                      <div className="tenantDetailDesc tenantDetLeavingReasonDet">
                        {vacantData?.leave_reason ?? 'N/A'}
                      </div>
                    </div>
                    <div className="col-sm-6 d-flex align-items-start custom_border_bottom mb-3">
                      <div className="tenantDetailTitle tenantDetLeavingReason">
                        Admin Note:
                      </div>
                      <div className="tenantDetailDesc tenantDetLeavingReasonDet">
                        {vacantData?.note?.description ?? 'N/A'}
                      </div>
                    </div>
                    {vacantData?.acknowledge_status !== VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED && vacantData?.bank_detail && (
                      <div className='col-12 mt-4'>
                        <div className='bankAccountDet'>
                          <h5 className='mb-3'>Bank Account Details</h5>
                        </div>
                        <Row>
                          <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                            <div className='tenantDetailTitle'>Bank Name:</div>
                            <div className='tenantDetailDesc'>
                              {vacantData?.bank_detail?.bank_name ?? 'N/A'}
                            </div>
                          </div>

                          <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                            <div className='tenantDetailTitle'>IFSC:</div>
                            <div className='tenantDetailDesc'>
                              {vacantData?.bank_detail?.ifsc ?? 'N/A'}
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                            <div className='tenantDetailTitle'>Account Name:</div>
                            <div className='tenantDetailDesc'>
                              {vacantData?.bank_detail?.account_holder_name ?? 'N/A'}
                            </div>
                          </div>

                          <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                            <div className='tenantDetailTitle'>Account Number:</div>
                            <div className='tenantDetailDesc'>
                              {vacantData?.bank_detail?.account_number ?? 'N/A'}
                            </div>
                          </div>
                        </Row>
                      </div>

                    )}
                  </Row>
                  {hasPaymentPermission && (
                    <div className="gen_leave_add_btn text-center">
                      <Button
                        className="yellow_gradient_btn pay_full_amt mt-3"
                        disabled={!(vacantData?.acknowledge_status === VACANT_REQUEST_ACKNOWLEDGE_STATUS.ACKNOWLEDGED && moment().isSameOrAfter(moment(vacantData?.leaving_date, 'DD-MM-YYYY')))}
                        onClick={() => {
                          handleOpenRefundModal()
                        }}
                      >
                        Refund Deposit
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <>
                <OffcanvasHeader
                  toggle={toggleDrawer}
                  style={{
                    marginTop: "-3vh",
                    marginLeft: "-1vw",
                  }}
                />
                <ActivityTimeline
                  module={ALL_MODULES.PG_VACANT_REQUESTS}
                  module_id={reqId}
                  activityCompHeight={70}
                />
              </>
            </CustomTabPanel>
          </Box>

          {isRefundModal && (
            <InitiateRefundAmount
              isOpen={isRefundModal}
              handleClose={handleCloseRefundModal}
              depositDetails={selectedRow?.refund_deposit}
              handleSubmit={(data) => {
                handleStatusChange(
                  vacantData?.id,
                  VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED,
                  data.note,
                  data.refund_amount
                )
                handleCloseRefundModal()
              }}
            />
          )}
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
}
