import React, { useEffect, useState, } from 'react';
import {
    Card, Button,
    Input,
} from 'reactstrap';
import {
    Table, Tbody, Tr, Td
} from 'react-super-responsive-table';
import Box from "@mui/material/Box";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import { Autocomplete, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { SelectDropDownListPaginationComponent } from '../Common/SelectDropDownPaginationList';
import { getAllProperties } from '../../api/property';
import { getAllPropertyOwner } from '../../api/owner';
import { toast } from 'react-toastify';
import { fetchTenantBillsForOwner } from '../../api/rent-bill';
import NodataFound from '../Common/NoDataFound';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CircularProgress from '@mui/material/CircularProgress';
import PaginationComponent from '../Common/PaginationComponent';
import TableHeaders from '../Common/TableHeaders';
import { ACTIONS, ALL_MODULES, BILL_PAID_STATUS, DEFAULT_DATE_FORMAT, OPERATION_TYPE } from '../../common/constant';
import { getAllGlobalSettings } from '../../api/globalSettings';
import { createPayoutForOwner, paymentPayoutForOwner, updateCommentForOwner } from '../../api/payout-bill';
import ConfirmationModal from '../Common/ConfirmationModal';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import AddOrEditOrViewNoteModal from '../Common/AddOrEditOrViewNoteModal';
import { DetailsHeaderBlockForPayout } from './DetailsHeaderBlockForPayout';
import moment from 'moment/moment';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import NoDataIcon from "../../assets/images/no-data.png";
import { usePermissionGiven } from '../Hooks/UserPermission';
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';

function GeneratePayoutSlips() {
    document.title = ' Payout Slip | Dstayz';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState();
    const [selectedOwner, setSelectedOwner] = useState();
    const [initialPayoutAmount, setInitialPayoutAmount] = useState()
    const [selectedItemsForPayout, setSelectedItemsForPayout] = useState([]);
    const [dateRange, setDateRange] = useState();
    const [update, setUpdate] = useState(0);
    const [selectedDate, setSelectedDate] = useState();
    const [tenantHistoryList, setTenantHistoryList] = useState();
    const [paginationMeta, setPaginationMeta] = useState({});
    const [payoutPercentage, setPayoutPercentage] = useState();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [totalPayoutAmount, setTotalPayoutAmount] = useState(0);
    const [tenantRentSlipId, setTenantRentSlipId] = useState();
    const [noteModal, setNoteModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [customPayoutCount, setCustomPayoutCount] = useState();
    const [columnData, setColumnData] = useState();
    const [isPayment, setIsPayment] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [pickerOpen, setPickerOpen] = useState(false);
    const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
        action: null,
        item: null
    })
    const [params, setParams] = useState({
        limit: 10,
        page: 1,
    });
    const [ownerOptions, setOwnerOptions] = useState([])
    const [selectedRow, setSelectedRow] = useState();
    const { id } = useParams();
    const location = useLocation();
    const hasPaymentPermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.PAYOUT_PAYMENT)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const showInfo = Boolean(anchorEl);

    const navigateToRenSlip = () => {
        navigate('/payout-slips');
    }

    const columns = [
        { column_name: `Floor No.`, key_name: 'floor_no', sort_enabled: false, style: { minWidth: '70px' } },
        { column_name: `Flat No.`, key_name: 'flat_no', sort_enabled: false, style: { minWidth: '100px' } },
        { column_name: `Room`, key_name: 'room_no', sort_enabled: false, style: { minWidth: '100px' } },
        { column_name: `Tenant Name`, key_name: 'bed_type', sort_enabled: false, style: { minWidth: '140px' } },
        { column_name: `Amount of Electricity`, key_name: 'electricity_amount', sort_enabled: false, style: { minWidth: '150px' } },
        { column_name: `Other Amount`, key_name: 'other_amount', sort_enabled: false, style: { minWidth: '150px' } },
        { column_name: `Rent`, key_name: 'monthly_rent', sort_enabled: false, style: { minWidth: '100px' } },
        { column_name: `Payout%`, key_name: 'custom_payout_percentage', sort_enabled: false, style: { minWidth: '100px' } },
        { column_name: `Total Payout Amount`, key_name: 'total_amount', sort_enabled: false, style: { minWidth: '100px' } },
        { column_name: `Comment`, key_name: 'note', sort_enabled: false, style: { minWidth: '100px' } },
    ]

    useEffect(() => {
        fetchGlobalSettings()
        if (!id) {
            setColumnData([{
                column_name: '',
                key_name: '',
                sort_enabled: false,
                style: { minWidth: '50px' },
            }, ...columns])
        } else {
            setColumnData(columns)
        }
    }, [])

    const fetchGlobalSettings = async () => {
        try {
            setIsLoading(true)
            const response = (await getAllGlobalSettings({ search: 'payout_percentage' })).data.data
            setPayoutPercentage(response.data[0].payload)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (location?.state?.payout && id && payoutPercentage) {
            const payoutData = JSON.parse(location?.state?.payout)
            setSelectedRow(payoutData)
            setSelectedProperty(payoutData.property?.id)
            setIsLoading(true)
            handleTenantHistoryPayment({
                date_range: `${dayjs(payoutData.start_date).format('YYYY-MM-DD')}/${dayjs(payoutData.end_date).format('YYYY-MM-DD')}`,
                property_id: payoutData.property?.id,
                owner_id: payoutData?.user?.id,
                payout_slip_id: payoutData?.id
            })
        }
    }, [location?.state?.payout, payoutPercentage])

    useEffect(() => {
        if (selectedProperty && !id) {
            ownerListApi({ property_id: selectedProperty })
        }
    }, [selectedProperty])

    const ownerListApi = async (params) => {
        try {
            const response = (await getAllPropertyOwner(params)).data.data;
            const resultData = response.data.map((res) => {
                return {
                    id: res?.user.id,
                    name: res?.user?.name,
                    label: (
                        <div className='selectUserCombo d-flex justify-content-between align-items-center'>
                            <span>{res?.user?.name}</span> <span className="autocompleteId mx-4"> {res?.user?.code}</span>
                        </div>
                    ),
                    key: `${res?.user?.code}|${res?.user?.name}`,
                };
            });
            setOwnerOptions(resultData)
        } catch (error) {
            console.log(error);
        }
    };

    const handleTenantHistoryPayment = async (params) => {
        try {
            setIsLoading(true);
            const queryParams = id ? {
                ...params
            } : { ...params, owner_id: selectedOwner.id, date_range: dateRange }
            delete queryParams.property_id;
            const response = await fetchTenantBillsForOwner(id ? params?.property_id : selectedProperty, queryParams)
            if (response.status === 200) {
                setPaginationMeta(response.data.data.meta)
                let level = (response.data.data.meta.current_page - 1) * response.data.data.meta.per_page;
                let totalAmount = 0.0
                let custom_payout_count = 0
                const selectedIds = []
                const resultData = response.data?.data?.data?.map((res) => {
                    selectedIds.push(res.id)
                    let percentage = payoutPercentage.value
                    if (res?.tenant?.custom_payout_percentage > 0) {
                        percentage = res?.tenant?.custom_payout_percentage
                        custom_payout_count = custom_payout_count + 1
                    }

                    const payoutAmount = (res.electricity_amount + ((res.other_amount) * percentage / 100) + ((res.rent) * percentage / 100))
                    totalAmount = totalAmount + payoutAmount
                    return {
                        indexNo: ++level,
                        ...res,
                        total_amount: payoutAmount,
                        custom_payout_percentage: percentage
                    };
                });

                setCustomPayoutCount(custom_payout_count)
                setTotalPayoutAmount(totalAmount);
                setInitialPayoutAmount(totalAmount)
                setTenantHistoryList(resultData);
                setSelectedItemsForPayout(selectedIds)
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const paginationModelMethod = async (data) => {
        if (data?.page) {
            setParams((old) => ({
                ...old,
                page: data.page
            }));
            handleTenantHistoryPayment({ ...params, page: data.page })
        } else {
            setUpdate(prev => prev + 1)
            setParams((old) => ({
                ...old,
                page: 1,
                limit: data?.limit
            }));
            handleTenantHistoryPayment({ ...params, page: 1, limit: data?.limit, })
        }
    };

    const handleSave = async () => {
        try {
            const response = await createPayoutForOwner({
                status: BILL_PAID_STATUS.PENDING,
                property_id: selectedProperty,
                user_id: selectedOwner.id,
                date_range: dateRange,
                total_payout_amount: totalPayoutAmount,
                tenantRentSlipIds: selectedItemsForPayout
            })
            if (response?.status === 200) {
                toast.success(response.data.message);
                navigate('/payout-slips');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message)
        } finally {
            setIsConfirmModal(false);
            setIsLoading(false);
        }
    }

    const handleNoteModalToggle = (action, item, modalState) => {
        setNoteModalActionAndPayload({
            action,
            item: {
                ...item, note: {
                    description: item?.comment_for_owner
                }
            },
            module: ALL_MODULES.PG_VACANT_REQUESTS,
            isDisabled: item?.owner?.payout_Slips?.length !== 0
        })
        setNoteModal(prev => {
            if (modalState) {
                return modalState
            }
            return !prev
        })
    }


    const refresh = (id, data) => {
        const updatedResult = tenantHistoryList?.map((item) => {
            if (item.id === id) {
                return { ...item, ...data }
            } else {
                return item
            }
        })
        setTenantHistoryList(updatedResult);
    }

    const handleSubmit = async ({ note }) => {
        try {
            const response = await updateCommentForOwner(tenantRentSlipId, { comment: note })
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh(tenantRentSlipId, { comment_for_owner: note });
            } else {
                toast.error(response.data.message);
            }

            setNoteModal(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setNoteModal(false)
        } finally {
            setIsLoading(false);
        }
    }

    const handlePayment = async () => {
        try {
            setIsLoading(true);
            const payload = {
                total_amount: selectedRow?.total_payout_amount,
                payoutId: id
            }
            const res = await paymentPayoutForOwner(payload);
            if (res?.status == 200) {
                toast.success(res?.data?.message);
                setSelectedRow({ ...selectedRow, payment: res.data?.data?.payment, status: BILL_PAID_STATUS.PAID })
            }
        } catch (e) {
            setIsLoading(false);
            toast.error(e?.response?.data?.message || e.message);
        } finally {
            setIsPayment(false);
            setIsLoading(false);
        }
    }

    const handleDateRangeChange = (item) => {
        setTenantHistoryList()
        setSelectionRange(item.selection);
        const formattedStartDate = dayjs(item.selection.startDate).format(DEFAULT_DATE_FORMAT);
        const formattedEndDate = dayjs(item.selection.endDate).format(DEFAULT_DATE_FORMAT);
        setDateRange(`${dayjs(item.selection.startDate).format('YYYY-MM-DD')}/${dayjs(item.selection.endDate).format('YYYY-MM-DD')}`)
        setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)
        setPickerOpen(false);
    };

    return (
        <>
            {/* <div className="page-content"> */}
                <div className="container-fluid">
                    <div className="flexbox_holder d-flex align-items-center justify-content-between mb-3">
                        <div className="page-title-box pb-0 d-flex align-items-center">
                            <ArrowBackIcon onClick={navigateToRenSlip} className='cursor-pointer' />
                            <h4 className="mb-0 ms-2">{id ? 'View Generated Payout Slip' : 'Create Payout Slip'}</h4>
                            <div className='d-flex justify-content-end ms-4'>
                                {customPayoutCount > 0 ?
                                    <div className='custom_payout_percentage'>Custom Payout% Count: {customPayoutCount}</div> :
                                    <div className='payout_percentage'>
                                        Payout% : <span>{payoutPercentage?.value}{payoutPercentage?.unit_value}</span>
                                    </div>}
                            </div>
                            <div className='d-flex justify-content-end ms-2' key={update}>
                                {tenantHistoryList?.length > 0 && !id && (<div className='total_payout'> Total Payout Amount: {totalPayoutAmount.toFixed(2)} </div>)}
                            </div>
                        </div>
                        {!id && (
                            <div className='d-flex align-items-center'>
                                <Button
                                    className="red_bordered_btn me-2"
                                    disabled={!(selectedProperty && selectedOwner && selectedDate)}
                                    onClick={() => {
                                        setSelectedItemsForPayout([])
                                        setTenantHistoryList([])
                                        handleTenantHistoryPayment(params)
                                    }}
                                >
                                    <RemoveRedEyeOutlinedIcon /> Show Payout Slip
                                </Button>
                                <Button className="yellow_gradient_btn me-2 d-flex align-items-center" disabled={isLoading || (tenantHistoryList?.length > 0 ? !selectedItemsForPayout?.length > 0 : true)} onClick={() => setIsConfirmModal(true)} style={{ padding: '6px 10px' }}>
                                    Save
                                </Button>
                            </div>
                        )}
                        {hasPaymentPermission && id && selectedRow?.status !== BILL_PAID_STATUS.PAID && (
                            <Button className="yellow_gradient_btn me-2"
                                disabled={isLoading || (tenantHistoryList?.length > 0 ? false : true)}
                                onClick={() => setIsPayment(true)}
                            >
                                Pay
                            </Button>
                        )}
                    </div>
                    {!id ? (
                        <div className='page_filters mb-3'>
                            <div className='d-grid create_payslip_filters'>
                                <div className='filter_item'>
                                    <SelectDropDownListPaginationComponent
                                        label="Select Property"
                                        searchEnabled={true}
                                        listCall={getAllProperties}
                                        emitItem={(newValue) => {
                                            if (newValue === undefined) {
                                                setSelectedProperty()
                                                setSelectedOwner()
                                                setTenantHistoryList()
                                                setUpdate(update + 1)
                                            } else {
                                                setSelectedProperty(newValue?.id)
                                                setTenantHistoryList()
                                            }
                                        }}
                                    />
                                </div>
                                <div className='filter_item'>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        sx={{
                                            width: '100%'
                                        }}
                                        size="small"
                                        disabled={!selectedProperty}
                                        value={selectedOwner ? selectedOwner : null}
                                        options={ownerOptions}
                                        getOptionLabel={(option) => (option ? option.key : "")}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                key={option.id}
                                                {...props}
                                            >
                                                {option.label}
                                            </Box>
                                        )}
                                        onChange={(event, newValue) => {
                                            if (newValue === undefined) {
                                                setSelectedOwner()
                                                setTenantHistoryList()
                                                setUpdate(update + 1)
                                            } else {
                                                setSelectedOwner(newValue)
                                                setTenantHistoryList()
                                            }
                                        }}
                                        onInputChange={(event, newValue) => {
                                            setSearchQuery(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Owner"
                                                className="selectUserCombo justify-content-between align-items-center"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        (selectedOwner && selectedOwner.label && selectedOwner.label.props) ? (
                                                            <><span className="mx-1">{selectedOwner.key?.split("|")[1]}</span>
                                                                <span className="autocompleteId me-1"> {selectedOwner.key?.split("|")[0]}</span></>
                                                        ) : null
                                                    ),
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    value: (selectedOwner && selectedOwner.label && selectedOwner.label.props) ? '' : searchQuery
                                                }}
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                <div className='filter_item'>
                                    <TextField
                                        size="small"
                                        sx={{
                                            width: '100%'
                                        }}
                                        type="text"
                                        name='date_range'
                                        value={selectedDate || ''}
                                        label="Date Range"
                                        InputProps={{ readOnly: true }}
                                        onClick={() => setPickerOpen(!pickerOpen)}
                                    />
                                    {pickerOpen && (
                                        <DateRangePicker
                                            ranges={[selectionRange]}
                                            moveRangeOnFirstSelection={false}
                                            retainEndDateOnFirstSelection={true}
                                            onChange={handleDateRangeChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>) : (
                        <div
                            style={{
                                marginTop: `${4}vh`,
                                marginBottom: `${2}vh`,
                            }}
                        >
                                {selectedRow && !isLoading && (
                                    <DetailsHeaderBlockForPayout
                                        fields={[
                                            { filedName: "Property Name", value: selectedRow?.property?.name },
                                            { filedName: "Owner Name", value: selectedRow?.user?.name },
                                            { filedName: "Date Range", value: `${dayjs(selectedRow.start_date).format(DEFAULT_DATE_FORMAT)}  to  ${dayjs(selectedRow.end_date).format(DEFAULT_DATE_FORMAT)}` },
                                            { filedName: "Status", value: selectedRow?.status !== BILL_PAID_STATUS.PAID ? 'UnPaid' : `${selectedRow?.status} by ${selectedRow.payment?.creator?.name} at ${moment(selectedRow.payment?.payment_date).format('LLL')}` },
                                            { filedName: "Total Payout Amount", value: parseFloat(selectedRow?.total_payout_amount).toFixed(2) }
                                        ]}
                                    />
                                )}
                            </div>
                    )}
                    <Card className="table_card_holder">
                        <div className="table-rep-plugin">
                            <div
                                className="table-responsive mb-0"
                                data-pattern="priority-columns"
                                style={!tenantHistoryList ? { height: '400px' } : {}}
                            >
                                {!tenantHistoryList && isLoading && (
                                    <div style={{ textAlign: 'center', marginTop: '4px' }}>
                                        <CircularProgress />
                                    </div>
                                )}
                                {tenantHistoryList?.length === 0 && !isLoading && (
                                    <div style={{ textAlign: 'center', height: '400px' }}>
                                        <NodataFound text="No tenant pay the rent to this tenure" icon={NoDataIcon} />
                                    </div>
                                )}
                                {tenantHistoryList?.length > 0 && (
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-bordered rentSlipTable"
                                    >
                                        <TableHeaders
                                            headers={columnData}
                                            module={ALL_MODULES.FINANCE}
                                            shouldACtionDisplay={false}
                                            isAllRowsSelected={!id ?
                                                (selectedItemsForPayout?.length > 0 &&
                                                    tenantHistoryList.every((el) =>
                                                        selectedItemsForPayout.includes(el.id),
                                                    )) : false
                                            }
                                            emitAllRowSelect={(value) => {
                                                if (value && !id) {
                                                    setTotalPayoutAmount(initialPayoutAmount)
                                                    setSelectedItemsForPayout(tenantHistoryList.map((item) => item.id))
                                                } else {
                                                    setTotalPayoutAmount(0.0)
                                                    setSelectedItemsForPayout([])
                                                }
                                                setUpdate(update + 1)
                                            }}
                                        />
                                        <Tbody key={update}>
                                            {tenantHistoryList?.map((item, index) => (
                                                <Tr key={index}>
                                                        {!id && (
                                                        <Td onClick={(e) => e.stopPropagation()}>
                                                            <Input
                                                                type='checkbox'
                                                                className='form-check-input'
                                                                id='formrow-customCheck'
                                                                defaultChecked={selectedItemsForPayout?.includes(
                                                                    item?.id,
                                                                )}
                                                                onClick={() => {
                                                                    setSelectedItemsForPayout((prev) => {
                                                                        if (
                                                                            selectedItemsForPayout?.includes(item.id)
                                                                        ) {
                                                                            setTotalPayoutAmount((prev) => prev - item.total_amount)
                                                                            return prev.filter(
                                                                                (_v) => _v !== item.id,
                                                                            );
                                                                        } else {
                                                                            setTotalPayoutAmount((prev) => prev + item.total_amount)
                                                                            return [...prev, item.id];
                                                                        }
                                                                    });
                                                                    setUpdate(update + 1)
                                                                }}
                                                            />
                                                        </Td>
                                                        )}
                                                    <Td>{item.indexNo}</Td>
                                                    <Td>{item.floor?.name}</Td>
                                                    <Td>{item.flat?.name ? item.flat?.name : 'N/A'}</Td>
                                                    <Td>{item.room?.name}</Td>
                                                    <Td>{item.tenant?.user?.name}</Td>
                                                    <Td>{item?.electricity_amount}</Td>
                                                    <Td>{item.other_amount}
                                                        </Td>
                                                    <Td className="yellow_block">{item.rent}</Td>
                                                    <Td>{item?.custom_payout_percentage}%</Td>
                                                    <Td>
                                                        Rs. {parseFloat(item.total_amount).toFixed(2)}
                                                        <InfoIcon onClick={handleClick} className='ms-2' />
                                                        <Popover
                                                            className='popup_block'
                                                            id={item.id}
                                                            open={showInfo}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2 }} className='popContent'>
                                                                    <p>Payment Date: {dayjs(item.tenant?.payment_history[0]?.payment_date).format(DEFAULT_DATE_FORMAT)}</p>
                                                                <p>Transaction ID: {item.tenant?.payment_history[0]?.transaction_id}</p>
                                                            </Typography>
                                                        </Popover>
                                                    </Td>
                                                    <Td>
                                                        <Td className="d-flex justify-content-center">{item?.comment_for_owner ? (
                                                            <DescriptionIcon titleAccess='Show Note'
                                                                style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer', color: 'blue' }}
                                                                fontSize={'inherit'} color='info' onClick={() => {
                                                                    setTenantRentSlipId(item.id)
                                                                    handleNoteModalToggle(ACTIONS.VIEW, item)
                                                                }} />
                                                        ) : <NoteAddIcon titleAccess='Add Note'
                                                                    style={item.payout_slip_id ? { cursor: 'pointer', textAlign: 'center', fontSize: '28px', color: 'red' } : { cursor: 'not-allowed', textAlign: 'center', fontSize: '28px', color: 'gray' }}
                                                            fontSize={'inherit'}
                                                                    onClick={() => {
                                                                        if (item.payout_slip_id) {
                                                                            setTenantRentSlipId(item.id)
                                                                            handleNoteModalToggle(ACTIONS.CREATE, item)
                                                                        }
                                                                    }} />
                                                        }</Td>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                )}
                            </div>
                        </div>

                        {tenantHistoryList?.length > 0 && (<div className="numbers_pagination_holder">
                            <PaginationComponent
                                paginationMeta={paginationMeta}
                                handleChangePagination={paginationModelMethod}
                                update={update}
                            >
                            </PaginationComponent>
                        </div>)}
                    </Card>
                </div>
            {/* </div> */}

            {isConfirmModal && (
                <ConfirmationModal
                    action={ACTIONS.CREATE}
                    show={isConfirmModal}
                    onAcceptClick={() => {
                        handleSave();
                        return;
                    }}
                    onCloseClick={() => setIsConfirmModal(false)}
                    isDisabled={isLoading}
                />
            )}
            {
                noteModal && <AddOrEditOrViewNoteModal handleNoteModalToggle={handleNoteModalToggle} handleSubmit={handleSubmit} noteModalActionAndPayload={noteModalActionAndPayload} />
            }

            {isPayment && (
                <ConfirmationModal
                    action={""}
                    show={isPayment}
                    text={"payment the payout"}
                    onCloseClick={() => setIsPayment(false)}
                    onAcceptClick={() => handlePayment()}
                    isDisabled={isLoading}
                />
            )}
        </>
    );
}

export default GeneratePayoutSlips;
